@import "../styles/colors";
@import "../styles/typography";


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

textarea {
  width: 100%;
  max-width: 100%;
  height: 6rem;
}

// DarkMode

.mainTitleDark {
  color: $pineGreen;
  letter-spacing: 0.1rem;
  margin: 0.5rem 0 2rem 0;
  font-weight: bold;
  font-size: 30px;
}

.subTitleDark {
  font-family: $inter;
  color: $labelDark;
  margin: 1rem 0 0 0;
  font-weight: 300;
  font-size: 25px;
}

.createCardDark {
  background-color: $darktablebg;
  border-radius: 35px;
  // border-style: solid;
  border-width: 2px;
  border-color: #f0f0f0;
  z-index: 10;
  box-shadow: 1px 11px 11px $pineGreen;
  padding: 3rem;
  margin-bottom: 5rem;
  opacity: 0.9;
}

.backLinkDark {
  font-size: 20px;
  text-decoration: none;
  color: #9aafab;
  font-weight: 900;
}

//End of DarkMode

// .formInputs {
//   border-bottom: 3px solid rgb(179, 179, 179);
//   border-radius: 0px;
//   padding: 1.5rem;
//   margin: 0.5rem 0;
// }

.fundFormInputs {
  background-color: transparent;
  border-radius: 10px;
  border: solid 0.5px $pineGreen;
  padding: 1.4rem;
  height: 2.1rem;
  position: relative;
  // animation-name: formInputs;
  // animation-duration: 1.5s;
  // animation-timing-function: ease-in;
  font-size: 14px;
  color:$khaki;
}
textarea.fundFormInputs {
  padding: 0.7rem 1.4rem;
  height: 5.5rem;
}

.fundFormInputs:focus {
  outline: solid 1px $pineGreen;
}

.fundFormInputs::placeholder {
  font-size: 13px;
}

@keyframes formInputs {
  from {
    right: 100px;
    opacity: 0;
  }
  to {
    right: 0px;
    opacity: 1;
  }
}

.button {
  border-radius: 6px;
  color: white;
  display: inline;
  margin: 1rem auto;
  position: relative;
}

.submitButton {
  @extend .button;
  background-color: #6d9e93;
  // animation-name: formInputs;
  // animation-duration: 1.5s;
  // animation-timing-function: ease-in;
}

.deleteButton {
  @extend .button;
  background-color: #dc3545;
  // animation-name: formInputs;
  // animation-duration: 1.5s;
  // animation-timing-function: ease-in;
}

.submitButtonDisabled {
  background-color: #969696;
  color: white;
  display: inline;
  margin: 4rem auto;
}

.mainTitle {
  color: #858585;
  margin: 0.5rem 0 2rem 0;
  font-weight: bold;
  font-size: 35px;
  // text-align: center;
}

.subTitle {
  color: #b6b6b6;
  margin: 1rem 0 0 0;
  font-weight: bold;
  font-size: 25px;
  // text-align: center;
}

.createButton {
  margin-bottom: 2rem;
  border-radius: 6px;
  padding: 0.8rem 2rem;
  background-color: #6d9e93;
  text-decoration: none;
  color: white;
  display: table;
  font-size: 13px;
  position: relative;
  // animation-name: createButtonAnimation;
  // animation-duration: 1.5s;
  // animation-timing-function: ease-in;
  border: none;
}

.createButton:hover {
  background-color: #a5c6ac;
  color: #f0f0f0;
  font-weight: 600;
}

.createCard {
  border-radius: 35px;
  z-index: 10;
  box-shadow: 1px 15px 15px grey;
  padding: 3rem;
  margin-bottom: 5rem;
  background-color: white;
}

.backLink {
  font-size: 20px;
  text-decoration: none;
  color: #6d9e93;
  font-weight: 900;
  cursor: pointer;
}

.backLink:hover {
  color: #6d9e93;
}

//Input type File

input[type="file"] {
  display: none;
}

.fileInput {
  // border: 3px solid #6d9e93;
  // color: #6d9e93;
  border-radius: 50%;
  display: block;
  padding: 2rem;
  cursor: pointer;
  margin: 0.5rem 0;
}

.cryptoLogo {
  display: flex;
  align-items: center;
  justify-content: center;
}

.cryptoLogo img {
  border-radius: 50%;
}

// Checkbox

.checkbox {
  display: inline-flex;
  cursor: pointer;
  position: relative;
  margin: 0.25rem 0 3rem 0;
  // animation-name: createButtonAnimation;
  // animation-duration: 1.5s;
  // animation-timing-function: ease-in;
}

.checkbox > span {
  color: #768594;
  padding: 0.5rem 1rem;
}

.checkbox > input {
  height: 25px;
  width: 25px;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  border: 1px solid #34495e;
  border-radius: 6px;
  outline: none;
  transition-duration: 0.7s;
  background-color: white;
  cursor: pointer;
  margin-top: 0.5rem;
}

.checkbox > input:checked {
  border: 3px solid #cdf3eb;
  background-color: #6d9e93;
}

@mixin input-label($color) {
  color: $color;
  margin: .6rem 0;
  font-weight: 900;
  font-size: 12px;
}

.inputLabel {
  @include input-label(#858585);
}

.inputLabelDark {
  @include input-label(#b6b6b6);
}

@mixin select-input( $fontsColor, $selectColor) {
  background-color: transparent;
  color: $fontsColor;
  padding: 0.4rem 1rem;
  width: 100%;
  // margin-bottom: 1rem;
  border-radius: 6px;
  border-color: $pineGreen;
  font-size: 13px;
  height: 2.8rem;
  color: $selectColor;
  position: relative;
  // animation-name: createButtonAnimation;
  // animation-duration: 1.5s;
  // animation-timing-function: ease-in;
}

.selectInputDark {
  @include select-input( white, #b6b6b6);
}

.selectInput {
  @include select-input(black, #858585);
}

.selectInput:hover {
  cursor: pointer;
}

.selectInputDark:hover {
  cursor: pointer;
}

.formErrorNotice {
  font-size: 13px;
  font-style: italic;
  color: #cd5c5c;
  margin-top: 0.8rem;
}

// .selectOption{
//   font-size: 15px;
// }

.reqSymbol {
  color: #cd5c5c;
  font-style: italic;
}

@mixin dateTimeInput( $fontsColor, $selectColor) {
  background-color: transparent;
  color: $fontsColor;
  padding: 0.4rem 1rem;
  width: 100%;
  // margin-bottom: 1rem;
  border-radius: 6px;
  border: solid 0.5px $pineGreen;
  font-size: 15px;
  height: 3rem;
  color: $selectColor;
  position: relative;
  // animation-name: createButtonAnimation;
  // animation-duration: 1.5s;
  // animation-timing-function: ease-in;
}

.datetime {
  @include dateTimeInput( black, #858585);
}

.datetimeDark {
  @include dateTimeInput( white, #b6b6b6);
}

.datetimeDark::-webkit-calendar-picker-indicator {
  filter: invert(0.9);
  margin: 0px;
  margin-right: 20px !important;
}

.datetime::-webkit-calendar-picker-indicator {
  filter: invert(0.6);
  margin: 0px;
  margin-right: 20px !important;
}

@mixin holdingFormButtons($bg-color) {
  // margin: 2rem 0;
  padding: 0.5rem 1.5rem;
  font-size: 12px;
  color: #DAE7E4;
  border-radius: 6px;
  text-decoration: none;
  position: relative;
  // animation-name: createButtonAnimation;
  // animation-duration: .5s;
  // animation-timing-function: ease-in;
  background-color: $bg-color;
  border-style: none;
}

.editButton {
  @include holdingFormButtons(#6d9e93); 
}

.editButtonDark {
  @include holdingFormButtons(transparent);
  border-style: solid;
  border-width: 2px;
  border-color: #6d9e93;
}

.editButton:hover,
.editButtonDark:hover, 
.inputWithButton button:hover {
  background-color: #85ADA5;
  color: white;
  border-style: none;
}

.inputWithButton {
  position: relative;
  height: 2.5rem;
  width : 100%;
}

.inputWithButton input{
  border: solid 1px $pineGreen;
  font-size: 14px;
  position: relative;
  // animation-name: formInputs;
  // animation-duration: 1.5s;
  // animation-timing-function: ease-in;
  border-radius: 15px;
}

.inputWithButton button {
  position: absolute;
  right: 0;
  top : 0;
  height: 2.9rem;
  border-radius: 0 15px 15px 0;
  border-style: none;
  background-color: $pineGreen;
  color: #f0f0f0;
  font-size: 12px;
  font-weight: 600;
  padding: 0 1.5rem;
  // animation-name: formInputs;
  // animation-duration: 1.5s;
  // animation-timing-function: ease-in;
}

.tooltiptext {
  font-family: $robotoMono;
  visibility: hidden;
  background-color: $darktablebg;
  color: $labelDark;
  text-align: center;
  border-radius: 6px;
  padding: 0.2rem 1.5rem;
  font-size: 12px;
  border-style: solid;
  border-width: 1px;
  position: absolute;
  bottom: 2rem;
  z-index: 5;
  border-style: solid;
}

:where(.inputWithButton:hover)
  .tooltiptext {
  visibility: visible;
}

.textareaInput {
  border-radius: 10px;
  min-height: 20rem;
  padding: 0.5rem;
}