@import "../../styles/colors";
@import "../../styles/typography";

.tableButton {
  padding: 0.3rem 2rem;
  margin: 0 0.5rem;
  color: $pineGreen;
  background-color: transparent;
  border: none;
  border-bottom: solid 1px $pineGreen;
  transition: padding 1s;
}

.tableButton:hover {
  font-weight: 900;
  border-style: none;
  border-bottom: solid 1px $pineGreen;
  padding: 0.3rem 0.4rem;
}

.mainTitle {
  text-align: center;
  color: $labelDark;
  font-weight: 300;
  font-family: $robotoMono;
  font-size: 1.6rem;
  margin: 1rem 0 2rem 0;
  text-transform: uppercase;
  letter-spacing: .1em;
}

.headerTitle {
  text-align: center;
  color: #B5BDDB;
  font-size: 13px;
  letter-spacing: .1em;
  vertical-align: text-top;
  line-height: 1rem;
}

.headerData {
  text-align: center;
  color: $labelDark;
  font-weight: 900;
  display: inline-block;
  width: 6rem;
  margin: 0 0.5rem;
}

.headerData h4 {
  font-size: 1rem;
  line-height: 1rem;
  color: $labelDark;
  font-family: $robotoMono;
  text-align: center;
}

.currentBookingPeriod {
  text-align: center;
  font-size: 12px;
  color: $labelDark;
  font-style: italic;
}

.separator{
  margin: 0 35px
}

.reports p {
  text-align: center;
  font-size: 14px;
  color: $mossGreen;
}

.reportsDark span, .reports span {
  color: $pineGreen;
}

.reportsDark {
  text-align: center;
  font-size: 14px;
  color: $sand;
}

@mixin card-table($boxShadow, $bg) {
  border-radius: 10px;
  text-align: center;
  z-index: 5;
  margin: 2rem 0.5rem;
  padding: 1rem 0.5rem;
  background-color: $bg;
}

.cardTableDark {
  @include card-table(0px 0px 0px rgb(66, 66, 66), $darktablebg);
  background: rgb(80,94,91);
  background: linear-gradient(165deg, rgb(64, 105, 96) 0%, rgba(27,34,54,1) 13%);
  border: solid 1px $pineGreen;
  box-shadow: 1px 11px 25px $pineGreen;
}

.cardTable {
  @include card-table(1px 15px 15px grey, white);
  border-style: solid;
  border-width: 4px;
  border-color: #f0f0f0;
}

.mainChartsCardDark {
  @include card-table(0px 0px 0px rgb(66, 66, 66), $darktablebg);
  padding: 3rem;
  border: solid 1px $pineGreen;
  box-shadow: 1px 11px 25px $pineGreen;

}

.mainChartsCard {
  @include card-table(1px 15px 15px grey, white);

}

