$pineGreen: #6D9E93;
$mossGreen: #A5C6AC;
$forestGreen: #669771;
$darkGreen: #0E3531;
$khaki: #CACCBD;
$charcoal: #32322F;
$sand: #F6F3EE;
$label: #858585;
$labelDark: #b6b6b6;
$warningError: #CD5C5C;
$neon: #A4F34F;
$darktablebg: #1B2236