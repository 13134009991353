@import "../../styles/colors";
@import "../../styles/typography";


// Notifications dropdown
.dropbtn {
  color: $mossGreen;
  font-size: 40px;
  cursor: pointer;
  position: absolute;
  right: 5rem;
  top: -1.5rem;
}

.redBubble {
  background-color: $warningError;
  border-radius: 50%;
  text-align: center;
  font-size: 15px;
  margin-left: -3rem;
  z-index: 10;
  padding: 0.2rem 0.4rem;
  position: absolute;
  right: 5rem;
  top: -1.5rem;
  cursor: pointer;
}

.dropdown {
  width: 100vh;
}

.dropdownContent {
  display: none;
  position: absolute;
  right: 0;
  top: 1rem;
  border-radius: 10px;
  margin-right: 6rem;
  background-color: $darktablebg;
  width: 60%;
  max-height: 85vh;
  overflow-y: scroll;
  box-shadow: 10px 10px 45px rgba(0, 0, 0, 0.8);
  border: solid 1px rgba(73, 73, 73, 0.9);
  z-index: 5000;
  padding: 0.5rem;
  text-align: left;
  font-size: 15px;
  user-select: none;
  -webkit-user-select: none;
}

.dropdownContent option {
  font-size: 12px;
}

// Scrollbar Styling
::-webkit-scrollbar {
  width: 7px;
}

::-webkit-scrollbar-track {
  background: transparent;
  border: solid 1px transparent;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  background: transparent;
  border: solid 1px $pineGreen;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: $pineGreen;
}


// Settings Dropdown
.dropbtnSettings {
  color: $mossGreen;
  font-size: 42px;
  cursor: pointer;
  position: absolute;
  right: 1rem;
  top: -1.5rem;
}


.dropdownContentSettings {
  display: none;
  position: absolute;
  right: -5rem;
  top: 1rem;
  border-radius: 10px;
  margin-right: 6rem;
  background-color: $darktablebg;
  min-width: 20rem;
  box-shadow: 25px 20px 15px rgba(0, 0, 0, 0.6);
  z-index: 10;
  border: solid 1px rgba(73, 73, 73, 0.9);
  padding: 0.5rem;
  text-align: left;
  user-select: none;
  -webkit-user-select: none;
}

.dropdownContent option,
.dropdownContentSettings option,
.settingsLink {
  color: $khaki;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  font-weight: 100;
  cursor: pointer;
  border-bottom: solid 1px rgb(66, 66, 66);

}

.settingsLink:hover {
  color: $pineGreen;
  font-weight: 600;
}


.dropdownContent option:hover {
  color: $pineGreen;
}

.dropdownContentSettings option:hover {
  color: $pineGreen;
}

.title {
  margin-left: 1rem;
  margin-top: 1rem;
  font-size: 18px;
  color: $mossGreen;
  font-weight: 800;
  font-family: $roboto
}

// .dropdown:hover .dropdownContent {
//   display: block;
// }

// .dropdown:hover .dropdownContentSettings {
//   display: block;
// }


// Sidepopup Container

.sidepopupContainer {
  position: fixed;
  background-color: $darktablebg;
  border: solid 1px $pineGreen;
  border-radius: 25px 0 0 25px;
  padding: 2rem;
  height: 70vh;
  width: 60%;
  top: 10rem;
  right: 0;
  z-index: 1000;
  animation-name: sidepopup;
  animation-duration: 1s;
  animation-timing-function: ease-in;
}

@keyframes sidepopup {
  0% {
    transform: translateX(100%); /* Start off-screen to the right */
  }
  100% {
    transform: translateX(0); /* End at the original position */
  }
}


