@import "../.././styles/Forms.scss";

// Darkmode 
.listFrameDark {
  border-width: 0.2px;
  border-color: rgb(219, 219, 219);
  border-left-style: solid;
  border-right-style: solid;
  border-radius: 0 0 20px 20px;
  z-index: 7;
  box-shadow: 1px 15px 15px grey;
  color: white;
  margin: 0 1rem;
}

.listItemsDark {
  list-style: none;
  padding: 6px 0;
}

.listItemsDark:hover {
  background-color: rgb(219, 219, 219);
  color: #212121;
  cursor: pointer;
}

// End Darkmode

.mainInput {
  border-style: solid;
  border-width: 0.5px;
  border-radius: 15px;
  border-color: #cfcfcf;
  margin: 0 0 2rem 0;
}

.listItems {
  list-style: none;
  padding: 6px 0;
}

.listItems:hover {
  background-color: rgb(219, 219, 219);
  cursor: pointer;
}

.listFrame {
  border-width: 0.2px;
  border-color: rgb(219, 219, 219);
  border-left-style: solid;
  border-right-style: solid;
  border-radius: 0 0 20px 20px;
  z-index: 7;
  box-shadow: 1px 15px 15px grey;
  margin: 0 1rem;
  color: #212121;
}

