@import "../styles/colors.scss";

.image {
  display: block;
  width: 35%;
  margin: 4rem auto;
  text-align: center;
}

.title {
  text-align: center;
  margin: 2rem 0;
  color: $pineGreen;
}

.link {
    text-decoration: none;
    display: block;
    text-align: center;
    color: $pineGreen;
}

.link:hover {
    color: $pineGreen;
    font-weight: 800;
}