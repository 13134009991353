@import "../.././styles/AllEntities.scss";
@import "../.././styles/colors.scss";
@import "../.././styles/typography.scss";

.subTitle {
  color: #b4a9a9;
  font-size: 12px;
  margin: 0.5rem 0;
  text-align: center;
}

.subTitle span {
  color: $pineGreen;
  font-weight: 900;
  margin: 0 0.3rem;
  font-size: 12px;
  text-align: left;
}

.mainTitle {
  font-family: $robotoMono;
  color: $label;
  margin: 2rem 0;
  font-weight: 300;
}

.mainTitle span {
  font-family: $robotoMono;
  color: $labelDark;
  margin: 4rem 0;
  font-weight: 300;
}

.averagePrice {
  text-align: left;
  font-size: 13px;
  font-style: italic;
}

.averagePrice span {
  color: $pineGreen;
  margin: 0 0.3rem;
}

.tooltip {
  position: relative;
  // display: inline-block;
}

// Tooltips Icons AllOrders and OrdersImportList

@mixin tooltip-mixin($verticalPadding) {
  visibility: hidden;
  background-color: #fcf9f9;
  color: #2f3e46;
  text-align: center;
  border-radius: 6px;
  // padding: $verticalPadding 2rem;
  height: 1.5rem;
  font-size: 10px;
  font-weight: 200;
  opacity: 0.9;
  position: absolute;
  z-index: 1;
}

.tooltip .tooltiptext {
  @include tooltip-mixin(0.5rem);
  right: 0;
  bottom: 2rem;
}

.tooltip .tooltiptextIcons {
  @include tooltip-mixin(0.6rem);
  right: 0;
  bottom: 1rem;
  width: 10rem;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.tooltip:hover .tooltiptextIcons {
  visibility: visible;
}

.tooltip h5 {
  font-size: 13px;
  font-weight: 800;
  color: $label;
}

// Icons styling

.cryptoIcons {
  filter: grayscale(75%);
  opacity: 1;
  margin: 0.3rem 0;
  border-radius: 50%;
  border-style: solid;
  border-width: 2px;
  border-color: #f5f5f5;
}

.tableStyle {
  margin: 2rem 0;
}

.mainInput {
  border-style: solid;
  border-width: 0.5px;
  border-radius: 25px;
  border-color: #cfcfcf;
  margin: 0 2rem;
  width: 20%;
}

.headerTitle {
  text-align: left;
  color: $pineGreen;
  font-size: 15px;
  font-weight: 300;
}

.headerData {
  font-family: $robotoMono;
  text-align: left;
  font-size: 25px;
  margin: 1rem 0 0 0;
  color: $labelDark;
  font-weight: 300;
}

.icons {
  font-size: 18px;
  color: $pineGreen;
  margin-right: 0.1rem;
}

.iconsDisabled {
  font-size: 18px;
  color: $label;
  margin-right: 0.1rem;
}

@mixin orderButton($bg) {
  padding: 10px 25px;
  font-weight: 100;
  color: $labelDark;
  font-size: 13px;
  border-radius: 10px;
  text-decoration: none;
  background-color: $bg;
  margin-right: 2rem
}

.createOrderButtonDark {
  @include orderButton(transparent);
  border-style: solid;
  border-width: 1px;
  border-color: #6d9e93;
}

.createOrderButtonDark:hover {
  background-color: $pineGreen;
  color: white
}

.deleteTradesButton {
  @include orderButton($warningError);
  color: $sand;
  border: solid 1px $warningError;
}

.deleteAllTradesButton {
  @include orderButton(transparent);
  color: $sand;
  border: solid 1px $warningError;
}

.deleteTradesIcons {
  color: $sand;
  font-size: 23px;
  margin: 0 0.5rem
}

.createOrderButton {
  @include orderButton($pineGreen);
}

@mixin cardMixin($bg) {
  border-radius: 25px;
  padding: 2rem;
  box-shadow: 1px 15px 15px $pineGreen;
  margin: 1rem auto;
  background-color: $bg;
  animation-name: infoCard;
  position: relative;
  animation-duration: 1.5s;
  animation-timing-function: ease-in;
}

.cardDark {
  @include cardMixin($darktablebg);
  opacity: 0.8;
  background: rgb(27,34,54);
  background: linear-gradient(270deg, rgba(27,34,54,1) 0%, rgba(27,34,54,1) 74%, rgba(61,84,79,0.3) 100%);
}

.card {
  @include cardMixin(white);
  border-color: #f0f0f0;
  border-width: 4px;
  border-style: solid;
}

.cardtableDark {
  @include cardMixin($darktablebg);
  // opacity: 0.8;
}

@keyframes infoCard {
  from {
    right: 70px;
    opacity: 0;
  }
  to {
    right: 0px;
    opacity: 1;
  }
}

.importIcons {
  color: #6D9E93;
  font-size: 20px;
  text-align: center;
  transition: transform 1s;
}

.importIcons:hover {
  transform: scale(1.2);
  cursor: pointer;
  color: $sand;
}

.checkbox {
  display: inline-flex;
  cursor: pointer;
  position: relative;
}

.checkbox > span {
  color: #768594;
}

.checkbox > input {
  height: 20px;
  width: 5px;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  border: solid 1px $pineGreen;
  border-radius: 4px;
  outline: none;
  background-color: transparent;
  cursor: pointer;
}

.checkbox > input:checked {
  border: 1px solid #cdf3eb;
  background-color: #6d9e93;
}

.scrollableTable {
  height: 50rem;
  overflow-y: auto;
}

.stickyHeader {
  position: sticky;
  top: 0;
  z-index: 200;
  background: $darktablebg;
  height: 5rem;
}
