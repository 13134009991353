@import "../.././styles/Forms.scss";
@import "../.././styles/typography.scss";
@import "../.././styles/colors.scss";

// Darkmode
.listFrameDark {
  border-width: 0.2px;
  border-color: rgb(219, 219, 219);
  border-left-style: solid;
  border-right-style: solid;
  border-radius: 0 0 5px 5px;
  z-index: 7;
  box-shadow: 1px 15px 15px grey;
  color: white;
  margin: 0 1rem;
  padding: 0;
}

.listItemsDark {
  list-style: none;
  padding: 5px 0;
}

.listItemsDark:hover {
  background-color: rgb(219, 219, 219);
  border-radius: 0 0 5px 5px;
  color: #212121;
  cursor: pointer;
}

// End Darkmode

.listItems {
  list-style: none;
  padding: 6px 0;
}

.listItems:hover {
  background-color: rgb(219, 219, 219);
  border-radius: 0 0 5px 5px;
  cursor: pointer;
}

.listFrame {
  border-width: 0.2px;
  border-color: rgb(219, 219, 219);
  border-left-style: solid;
  border-right-style: solid;
  border-radius: 0 0 5px 5px;
  z-index: 7;
  box-shadow: 1px 15px 15px grey;
  margin: 0 1rem;
  color: #212121;
  padding: 0;
}

.theader th {
  color: #375750;
  text-align: center;
}

.tableStrips {
  border-bottom: 2px solid #f7f7f7;
}

.tableStripsDark {
  border-bottom: 2px solid #6e6e6e;
  color: white;
}

.theaderDark th {
  color: #5c8f84;
  text-align: center;
}

.tableInputs {
  height: 2rem;
  margin: 0.5rem 0;
  border-style: solid;
  border-color: rgb(207, 207, 207);
  border-radius: 10px;
}

.tableInputLabel {
  color: #375750;
  font-weight: bold;
  margin-bottom: 0.5rem;
  text-align: left;
  font-family: $robotoMono;
}

.tableInputLabelDark {
  color: #5c8f84;
  font-weight: bold;
  margin-bottom: 0.5rem;
  text-align: left;
  font-family: $robotoMono;
}

.importOrdersButton {
  background-color: transparent;
  border-style: solid;
  border-width: 1px;
  border-color: $pineGreen;
  border-radius: 15px;
  color: $sand;
  font-size: 12px;
  padding: 0.7rem 2.5rem;
  transition: background-color 1.5s, padding 1s;
}

.importOrdersButton:hover {
  background-color: $mossGreen;
  padding: 0.7rem 3rem;
}

.fundFormInputs::placeholder {
  text-transform: none;
}

// ORDER FORM SLIDER COMPONENT

.fundTitle span { 
  color: $sand;
  font-family: $inter;
  font-weight: 100;
  font-size: 17px;
}

.fundTitleNotFilled span { 
  color: $warningError;
  font-family: $inter;
  font-weight: 100;
  font-size: 17px;
}

.valuesContainer {
  text-align: center;
}

.valuesContainer span {
  font-size: 15px;
  color: $sand;
  font-family: $inter;
}

.percentageInput {
  background-color: transparent;
  font-family: $robotoMono;
  color: #FFFFFF;
  font-size: 18px;
  border-bottom: solid 1px grey;
  border-radius: 0;
  text-align: center;
  width: 50%;
  margin: 0 25%;
}

.percentageInput::placeholder {
  font-size: 14px;
}

.percentageLabel {
  text-align: center;
  margin: .5rem 0;
}

.percentageLabel span {
  font-family: $inter;
  font-style: italic;
  color: $sand;
  font-size: 12px;
}

@mixin orderSliderContainer($bg){
  border-radius: 15px;
  margin-top: 3rem;
  padding: 0.5rem;
  background-color: $bg;
  opacity: 0.8;
}


//Filled Order Percentage
.orderSliderContainerFilled {
  @include orderSliderContainer($pineGreen);
  border: solid 1px $mossGreen;
  animation-name: filledAnimation;
  animation-duration: 1s;
}

@keyframes filledAnimation {
  20% {
    transform: translate3d(-1px, 0, 0);
  }
  
  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}

.orderSliderContainerFilled h5 {
  color: $khaki;
  text-align: center;
  font-weight: 600;
}

.orderSliderContainerFilled h6 {
  color: $khaki;
  text-align: center;
  font-weight: 600;
  font-size: 25px;
}

// Not Filled Order Percentage
.orderSliderContainerNotFilled {
  @include orderSliderContainer(transparent);
  border: solid 1px $warningError;
}

.orderSliderContainerNotFilled h5 {
  color: $khaki;
  text-align: center;
  font-weight: 600;
}

.orderSliderContainerNotFilled h6 {
  color: $pineGreen;
  text-align: center;
  font-weight: 600;
  font-size: 25px;
}

.removeButton {
  margin-left: -2rem;
  margin-top: .6rem;
  position: absolute;
  background: transparent;
  color: $label;
  border: none;
  font-size: 15px;
  font-weight: 900;
  animation-name: formInputs;
  position: relative;
  animation-duration: 1.5s;
  animation-timing-function: ease-in;
} 

.removeButton:hover {
  color: $pineGreen;
}

@keyframes formInputs { from { opacity: 0;} to { opacity: 1; } }

.blockchainSubmitButton { 
  border-radius: 10px;
  font-size: 12px;
  padding: .8rem 3rem;
  margin: 2rem 0 0 0;
  border: solid 1px $pineGreen;
  background: $pineGreen;
  color: $sand;
  width: 100%;
}

.blockchainSubmitButton:hover {
  background-color: $mossGreen;
}