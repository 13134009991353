@import "../../../styles/typography";
@import "../../../styles/colors";

.layerTooltip {
  background-color: $darktablebg;
  color: $labelDark;
  text-align: center;
  border-radius: 6px;
  padding: 0.2rem 1.5rem;
  font-size: 12px;
  border-style: solid;
  border-width: 0.5px;
  border-color: $label;
  font-family: $robotoMono;
}
