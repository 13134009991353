@font-face {
  font-family: "Poppins";
  src: local("Poppins"), url("../fonts/hodl-fonts/Poppins/Poppins-Light.ttf");
}

@font-face {
  font-family: "Inter";
  src: local("Inter"), url("../fonts/hodl-fonts/Inter/static/Inter-Light.ttf");
}

@font-face {
  font-family: "Roboto";
  src: local("Roboto"), url("../fonts/hodl-fonts/Roboto/Roboto-Light.ttf");
}

@font-face {
    font-family: "RobotoMono";
    src: local("RobotoMono"), url("../fonts/hodl-fonts/roboto-mono/RobotoMono-Light.ttf");
}

@font-face {
  font-family: "LarkenMedium";
  src: local("LarkenMedium"), url("../fonts/hodl-fonts/Larken/LarkenWeb/larken-medium/webfonts/larken-medium.ttf");
}

@font-face {
  font-family: "LarkenLight";
  src: local("LarkenLight"), url("../fonts/hodl-fonts/Larken/LarkenWeb/larken-light/webfonts/larken-light.ttf");
}

@font-face {
  font-family: "IngramMonoRegular";
  src: local("IngramMonoRegular"),url("../fonts/hodl-fonts/IngramMono/ingram-mono-regular/webfonts/ingram-mono-regular.ttf"); /* Adjust the path and format as needed */
}

$poppins: "Poppins";
$inter: "Inter";
$roboto: "Roboto";
$robotoMono: "RobotoMono";
$larkenMedium: "LarkenMedium";
$larkenLight: "LarkenLight";
$ingramMonoRegular: "IngramMonoRegular"
