@import "../../../../styles/colors";

.modal {
  position: fixed;
  z-index: 100;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}

.modalContent {
  background-color: #fefefe;
  padding: 5rem;
  width: 60%;
  margin: auto;
  border-radius: 15px;
  color: black;
  animation-name: modal-animation;
  position: relative;
  animation-duration: 1.5s;
  animation-timing-function: ease-in;
  vertical-align: middle;
}

@keyframes modal-animation {
  from {
    right: 70px;
    opacity: 0;
  }
  to {
    right: 0px;
    opacity: 1;
  }
}

.close {
  color: $pineGreen;
  float: right;
  font-size: 35px;
  font-weight: bold;
}

.close:hover {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.message {
  color: $pineGreen;
  font-size: 25px;
  margin-top: 3rem;
  font-weight: 600;
}
.codeText {
  color: $pineGreen;
  margin-top: 1rem;
  font-size: 15px;
}

.codeTextList {
  color: $pineGreen;
}
