@import "../../styles/AllEntities.scss";
@import "../../dashboard/Cryptos/CryptoForm.module.scss";


// AttachmentComponents
.fileInput {
  padding: .5rem 1.3rem;
  border: solid 1px $pineGreen;
  border-radius: 50%;
  position: absolute;
  top: -1.5rem;
  right: 4rem;
  z-index: 10;
  font-size: 30px;
  font-weight: 900;
  transition: transform .5s;
}

.iconLabel {
  color: $labelDark;
  transition: text-decoration 1s;
}

.fileInput:hover {
  background-color: $pineGreen;
  transform: scale(1.2);
}

.fileInput:hover .icon {
  color: $sand;
  transform: scale(1.1);
}

.clipboardContainer {
  position: relative;
  color: $pineGreen;
  padding: 0.2rem;
  padding-left: 0;
  width: 70%;
  margin: auto;
  transition: transform .5s;
}

.clipboardContainer:hover {
  cursor: pointer;
  transform: scale(1.1);
}

.clipboardContainer span {
  position: absolute;
  top: -.1rem;
  left: 55%;
  transform: translate(-50%, -50%);
  color: $sand;
  background-color: $warningError;
  border-radius: 50%;
  padding: 0.1rem .3rem;
  font-size: 10px;
  z-index: 10;
  cursor: pointer;
}

.clipboardIcon {
  font-size: 20px;
  margin-left: 0;
}

// AttachmentComponents Form
.formImg {
  width: 20%;
}

.inputContainer {
  display: flex;
  justify-content: center;
}

.textarea {
  width: 20%;
  border-radius: 8px;
  margin: 1rem 0 .5rem 0;
}

.uploadButton {
  border: none;
  background-color: $pineGreen;
  color: $sand;
  border-radius: 8px;
  width: 20%;
  padding: .5rem 0;
  margin: 1.5rem 0 .5rem;
}


// DiplayImage Component
.imageContainer {
  position: relative;
  border: solid 1px $pineGreen;
  border-radius: 15px;
  margin: 1rem 0;
  height: 20rem;
  overflow: hidden;
}

.imageContainerImg {
  height: 100%;
  width: 100%;
  border-radius: 15px;
  object-fit: cover;
  transition: all .3s
}

.imageContainerDownload, .imageContainerDelete {
  visibility: hidden;
  position: absolute;
  right: 1rem;
  top: 1rem;
  color: $pineGreen;
  font-size: 20px;
  transition: transform .5s;
}

.imageContainerDelete {
  right: 3rem;
  top: 1.5rem
}

.imageContainerName {
  position: absolute;
  font-size: 18px;
  color: $pineGreen;
  font-weight: 900;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  visibility: hidden;
  transition: visibility .5s
}

.imageContainer:hover .imageContainerImg {
  opacity: 0.1;
  cursor: pointer;
}

.imageContainer:hover .imageContainerName, .imageContainer:hover .imageContainerDownload, .imageContainer:hover .imageContainerDelete {
  visibility: visible;
}

.imageContainerName:hover, .imageContainer:hover {
  cursor: pointer;
}

.imageContainerDownload:hover, .imageContainerDelete:hover {
  cursor: pointer;
  color: $sand;
  transform: scale(1.2);
}

// Images Carousel Component
.backToGalleryLink {
  font-size: 14px;
}

.backToGalleryLink:hover {
  cursor: pointer;
  text-decoration: underline;
  color: $pineGreen;
}