@import "../../../styles/typography";
@import "../../../styles/colors";

.subTitle {
  font-family: $roboto;
  color: $pineGreen;
  text-align: left;
  font-weight: 600;
}

.text {
    color: $labelDark;
    text-align: left;
    font-size: 13px;
}

.featureContainer {
  padding: 2rem;
  width: 100%;
  margin: 2rem auto;
}

@mixin themeImageMixin($imageUrl) {
  width: 90%;
  height: auto;
  background-image: url($imageUrl);
  background-size: cover;
  height: 15rem;
  border-radius: 12px;
  transition: transform 2s, box-shadow 2s;
  border: solid 1px $pineGreen
}

.themeImageDark {
  @include themeImageMixin("../../../images/darkmode.png");
}

.themeImageLight {
  @include themeImageMixin("../../../images/lightmode.png");
}

.themeImageDark:hover,
.themeImageLight:hover {
  cursor: pointer;
  transform: scale(1.1);
  box-shadow: 10px 10px 15px $pineGreen;
}

.conditionsList p {
  color: $pineGreen;
  text-align: center;
  font-size: 0.8rem;
  font-style: italic;
}

.formErrorNotice {
  font-size: 13px;
  font-style: italic;
  color: $warningError;
  margin-top: 0.2rem;
  text-align: center;
}

.registerButton {
  width: 50%;
  margin: 1rem auto;
  background-color: $pineGreen;
  margin-top: 1rem;
  border-radius: 10px;
  border: none;
  padding: 0.4rem;
  display: block;
  color: white;
  font-size: 14px;
}

.registerButton:hover {
  background: #a5c6ac;
}

.input {
  border-radius: 10px;
  border: solid 1px $pineGreen;
  background-color: transparent;
  margin: 1rem 0;
  color: $labelDark
}

// MFA Options
.MFAOptionsContainer {
  border: solid 1px $pineGreen;
  padding: 1rem;
  border-radius: 15px;
  transition: height 4s;
}

.dynamicContainer {
  animation-name: tableHead;
  position: relative;
  animation-duration: 1.5s;
  animation-timing-function: ease-in;
}

@keyframes tableHead {
  from {
    right: 70px;
    opacity: 0;
  }
  to {
    right: 0px;
    opacity: 1;
  }
}

// Checkbox
.checkbox {
  display: inline-flex;
  cursor: pointer;
  position: relative;
  margin: 0.25rem 0 3rem 0;
  animation-name: createButtonAnimation;
  animation-duration: 1.5s;
  animation-timing-function: ease-in;
}

.checkbox > span {
  color: #768594;
  padding: 0.5rem 1rem;
}

.checkbox > input {
  height: 25px;
  width: 25px;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  border: 1px solid #34495e;
  border-radius: 4px;
  outline: none;
  transition-duration: 0.7s;
  background-color: white;
  cursor: pointer;
  margin-top: 0.5rem;
}

.checkbox > input:checked {
  border: 3px solid #cdf3eb;
  background-color: #6d9e93;
}

.roleCard {
  animation-name: role-cards;
  position: relative;
  animation-duration: 1.5s;
  animation-timing-function: ease-in;
}

.roleCard p {
  text-align: left;
  font-size: 20px;
  font-family: $inter;
  color: $pineGreen;
  padding: 1rem 0 1rem 0.5rem;
  background: transparent;
  border-radius: 15px;
  border: solid 1px $mossGreen;
}

@keyframes role-cards {
  from {
    right: 70px;
    opacity: 0;
  }
  to {
    right: 0px;
    opacity: 1;
  }
}

.icon {
  font-size: 25px;
  margin: 0 0.5rem;
}

// MFA code input

.codeContainer {
  display: flex;
  justify-content: center;
}

.codeInput {
  text-align: center;
  border-radius: 5px;
  padding: 0;
}

.inputTitle {
  color: $sand;
  text-align: center;
  margin: 1rem 0;
  font-size: 22px;
}

.message {
  color: $sand;
  text-align: center;
  font-style: italic;
  font-size: 13px;
}
