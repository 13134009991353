.multi-range-slider .thumb .caption * {
	position: absolute;
	min-width: 30px;
	height: 30px;
	font-size: 75%;
	text-align: center;
	line-height: 30px;
	background-color: #A5C6AC;
	border-radius: 8px;
    border: none;
	color: white;
	box-shadow: 0px 0px 5px #A5C6AC;
	padding: 0px 10px;
	white-space: nowrap;
}

.multi-range-slider .bar-inner { 
    border: none;
}

.multi-range-slider .thumb::before {
	border: none;
}
