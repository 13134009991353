@import "../../../styles/colors";

.sliderContainer {
  margin: 4rem 0;
}

.sliderContainer h5 {
  color: $sand;
  font-size: 17px;
  display: inline-block;
}

.datesDisplay button {
  background-color: $pineGreen;
  width: 30%;
  margin: 1rem auto;
  color: $sand;
  border-radius: 9px;
  border: none;
  padding: .5rem ;
  display: flex;
  justify-content: center;
}