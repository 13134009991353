@import "../../styles/colors";
@import "../../styles/typography";
@import "../../styles/mixins/selectInput.scss";

@media only screen and (min-width: 1300px) {
  .columnLayout { width: 33%; display: inline-block; }
}

@media only screen and (max-width: 800px) {
  .columnLayout { width: 100%; display: inline-block; }
}

.mainTitle {
  text-align: center;
  color: $label;
  font-weight: 500;
  font-family: $inter;
  font-size: 28px;
  margin: 1rem 0 2rem 0;
  text-transform: uppercase;
  letter-spacing: .1em;
}

.headerTitle {
  text-align: center;
  color: #B5BDDB;
  font-size: 13px;
  letter-spacing: .1em;
  vertical-align: text-top;
  line-height: 1rem;
}

.chartTitle {
  font-family: $inter;
  text-align: center;
  color: $label;
  font-size: 25px;
  font-weight: 300;
  margin: 1rem 0;
}

.headerData {
  text-align: center;
  margin: 2rem 0 0 0;
  color: $label;
  font-weight: 900;
  display: inline-block;
  width: 6rem;
  margin: 0 auto;
  font-family: $inter;
}

.headerData h4 {
  font-size: 1rem;
  line-height: 1rem;
  color: $label;
  font-family: $inter;
  text-align: center;
}

.separator{
  margin: 0 35px
}

.reports p {
  text-align: center;
  font-size: 14px;
  color: $mossGreen;
}

.reportsDark span, .reports span {
  color: $pineGreen;
}

.reportsDark p {
  text-align: center;
  font-size: 14px;
  color: $sand;
}

.linkIcon {
  margin: auto 2rem;
  color: $pineGreen;
  font-size: 20px;
  // float: right;
}


@mixin card-table($boxShadow, $bg) {
  border-radius: 10px;
  text-align: center;
  z-index: 5;
  margin: 2rem 0.5rem;
  padding: 1rem 0.5rem;
  box-shadow: $boxShadow;
  background-color: $bg;
}


.cardTableDark {
  @include card-table(0px 0px 0px rgb(66, 66, 66), $darktablebg);
  background: rgb(80,94,91);
  background: linear-gradient(165deg, rgb(64, 105, 96) 0%, rgba(27,34,54,1) 13%);
  box-shadow: 2px 10px 7px $pineGreen;
}

.cardTable {
  @include card-table(1px 15px 15px grey, white);
  border-style: solid;
  border-width: 4px;
  border-color: #f0f0f0;
}

.currentBookingPeriod {
  text-align: center;
  font-size: 12px;
  color: $label;
  font-style: italic;
  font-family: $inter;
}

.selectInputDark {
  @include select-input($darktablebg, white, #b6b6b6);
}

.selectInput {
  @include select-input(white, black, #858585);
}

.cardLinks {
  text-decoration: none;
  color: $pineGreen;
  margin: 1rem;
  font-size: 12px;
  font-family: $inter;
}

.cardLinks:hover {
  color: $pineGreen;
  font-weight: 600;
  text-decoration: underline;
}
