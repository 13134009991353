@import "../../../styles/colors";
@import "../../../styles/AllEntities.scss";

.barContainer {
  width: 100%;
}

.sliderContainer {
  border-radius: 10px;
  border: solid 1px $warningError;
  padding: 0 2rem;
  height: 14rem;
}

.slider {
  width: 100%;
  height: 7px;
  background: $labelDark;
  opacity: 0.8;
  outline: none;
  transition: 0.3s;
  -webkit-appearance: none;
  margin: 2rem 0;
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  appearance: none;
  -webkit-appearance: none;
  width: 5px;
  height: 30px;
  background: $pineGreen;
  cursor: pointer;
  border-radius: 10px;
}

.slider::-moz-range-thumb {
  width: 3px;
  height: 30px;
  background: $pineGreen;
  cursor: pointer;
}

.text {
  color: $pineGreen;
  text-align: center;
  font-weight: 900;
  font-size: 13px;
}

.amount {
  color: $labelDark;
  font-weight: 100;
  text-align: center;
  font-size: 18px;
}