@import "../../styles/colors";
@import "../../styles/typography";
@import "../../styles/AllEntities";
@import "../../styles/Forms";

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

textarea {
  width: 100%;
  max-width: 100%;
  height: 6rem;
}

.fundFormInputs {
  background-color: transparent;
  border-radius: 10px;
  border: solid 0.5px $pineGreen;
  padding: 1.4rem;
  height: 2.1rem;
  position: relative;
  font-size: 14px;
  color: $khaki;
}

textarea.fundFormInputs {
  padding: 0.7rem 1.4rem;
  height: 5.5rem;
}

.fundFormInputs:focus {
  outline: solid 1px $pineGreen;
}

.fundFormInputs::placeholder {
  font-size: 13px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

textarea {
  width: 100%;
  max-width: 100%;
  height: 6rem;
}

.fundFormInputs {
  background-color: transparent;
  border-radius: 10px;
  border: solid 0.5px $pineGreen;
  padding: 1.4rem;
  height: 2.1rem;
  position: relative;
  font-size: 14px;
  color: $khaki;
}

textarea.fundFormInputs {
  padding: 0.7rem 1.4rem;
  height: 5.5rem;
}

.fundFormInputs:focus {
  outline: solid 1px $pineGreen;
}

.fundFormInputs::placeholder {
  font-size: 13px;
}

tr, th, td {
  border: none !important;
}