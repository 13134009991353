@import "../../../styles/colors";
@import "../../../styles/Dashboards.scss";
@import "../../../styles/AllEntities.scss";
@import "../../../styles/typography";

.searchInput {
  border-radius: 5px;
  background-color: transparent;
  border: solid 1px $pineGreen;
  color: $pineGreen;
  font-weight: 600;
}

.button {
  background-color: $pineGreen;
  border: none;
  border-radius: 15px;
  padding: 0.8rem 2rem;
  color: $khaki;
  margin: 0;
}

.button:disabled,
.button[disabled] {
  border: 1px solid $label;
  background-color: transparent;
  color: $label;
}

.listUl {
  list-style: none;
}

.listLi {
  margin: 0.5rem auto;
}

.listLi:hover {
  cursor: pointer;
  background-color: $pineGreen;
  border-radius: 5px;
}

.icons {
  color: $pineGreen;
  font-size: 20px;
  display: inline;
}

.icons:hover {
  cursor: pointer;
  color: #738a84;
}

.tooltip {
  position: relative;
}

.tooltip .tooltiptext {
  visibility: hidden;
  background-color: $darktablebg;
  color: $mossGreen;
  text-align: left;
  border-radius: 6px;
  border: solid 1px $mossGreen;
  padding: 1rem;
  font-size: 13px;
  font-weight: 600;
  position: absolute;
  z-index: 5;
  min-width: 15rem;
  letter-spacing: .7px;
}

.tooltiptext ul li span {
  font-weight: 200;
  letter-spacing: normal;
  margin-left: .3rem;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.errorTitle {
  color: $pineGreen;
  margin: 3rem 0;
  font-size: 30px;
}

.errorSubtitle {
  font-size: 20px;
}

.container {
  white-space: nowrap;
  margin: 1rem 0;
}

.childElement {
  min-width: 150px;
  max-width: 150px;
  max-height: 10rem;
  padding: 1rem;
  display: inline-block;
  margin: 0 0.3rem;
  text-align: center;
  background-color: $darktablebg;
  border-radius: 15px;
  border: solid 1px $pineGreen;
  transition: transform .5s;
}

.childElement:hover {
  transform: scaleY(1.05);
}

.container h5 {
  font-size: 14px;
  font-weight: 600;
  color: $pineGreen;
  text-align: center;
  user-select: none;
  font-family: $inter;
}

.container h4 {
  font-size: 14px;
  user-select: none;
  color: $khaki;
  font-family: $inter;
}

.dragContainer, .notDragContainer {
  overflow-x: hidden;
  overflow-y: hidden;
  min-height: 100vh;
  margin: 3rem 0;
}

.dragContainer:hover {
  cursor: grabbing;
}

.notDragContainer:hover {
  cursor: grab;
}

.cardInput { 
  background-color: #FFFFFF;
  color: $charcoal;
  border-radius: 0px;
  text-align: left;
  padding-left: .5rem;
  box-shadow: inset 5px 5px 7px grey;
}

.cardInput::placeholder {
  text-align: left;
  user-select: none;
  font-family: $inter;
  font-size: 12px;
  padding-left: .5rem;
  color: rgb(177, 177, 177);
}

.sandboxInputFilter {
  width: 30rem;
  border-radius: 10px;
  background: transparent;
  border: solid 1px #6D9E93;
  color: #FFFFFF;
}

.sandboxInputFilter::placeholder {
  user-select: none;
  font-family: $inter;
}

@mixin percentage-cell($bgColor) {
  text-align: center;
  background-color: $bgColor;
}

.negativePercentage {
 @include percentage-cell($pineGreen)
}

.positivePercentage {
  @include percentage-cell($warningError)
}

.table {
  table-layout: fixed;
  width: 1900px;
}

.table td, .table th {
  user-select: none;
  border: solid 1px $label;
  font-size: 11px;
  width: 7rem;
}

.table th {
  padding: .5rem
}

.tableStrips td {
  color: $charcoal;
  border-bottom: $charcoal;
}

.tableStripsDark:hover, .tableStrips:hover {
  color: $khaki;
  font-weight: 900;
}

.tableStripsDark {
  border-bottom: none;
}

.dragIcon:hover {
  cursor: grab;
}

