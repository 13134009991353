@mixin previous-style($border-color) {
  background-color: transparent;
  text-decoration: none;
  color: $border-color;
  padding: 0.5rem;
}

.prevStyle {
  @include previous-style(#6d9e93);
}

.prevStyleDark {
  @include previous-style(white);
}

@mixin page-link($border-color) {
  border-style: solid;
  border-radius: 20px;
  border-width: 0.5px;
  border-color: $border-color;
  color: $border-color;
  padding: 0.5rem;
  text-decoration: none;
}

.pageLink {
  @include page-link(#6d9e93);
}

.pageLinkDark {
  @include page-link(white);
}

.pageLink:hover,
.prevStyle:hover, .pageLinkDark:hover,
.prevStyleDark:hover {
  color: #6d9e93;
  font-weight: 900;
}

.paginationContainer {
  background-color: transparent;
  text-decoration: none;
  padding: 0.5rem;
}

.paginationParentContainer {
  bottom: 0;
}

.activePage {
  background-color: #6d9e93;
  border-radius: 20px;
  padding: 8px 0;
  border-style: none;
  margin: 0 0.5rem;
}

.breakLink {
  background-color: transparent;
  text-decoration: none;
  border-radius: 20px;
  padding: 0.5rem;
  border-style: none;
  margin: 0.5rem;
  color: #6d9e93;
  font-size: 20px;
}

.breakLink:hover {
  color: #6d9e93;
  font-weight: 900;
}