@import "../../styles/Forms.scss";
@import "../../styles/AllEntities.scss";
@import "../../styles/colors";
@import "../../styles/typography";

.navTags {
  // height: 400px;
  animation-name: navTags;
  position: relative;
  animation-duration: .5s;
  animation-timing-function: ease-in;
}

.navTags h5 {
  font-family: $robotoMono;
  font-size: 1.2rem;
  color: $pineGreen;
  font-weight: 300;
  margin: 1rem 0;
  text-align: justify;
}

.navTags h5 span {
  color: $label;
  font-size: 18px;
  font-style: italic;
}

.navTags h5 strong {
  font-family: $inter;
  color: $label;
  margin-right: 0.2rem;
  font-weight: 200;
  font-size: 1rem;
}

.navTags h4 {
  font-family: $robotoMono;
  font-size: 20px;
  color: $pineGreen;
  font-weight: 900;
  margin: 1rem 0;
  text-align: justify;
}

.reportTitle {
  font-family: $inter;
  text-align: left;
  margin: 4rem 0 0.5rem 0;
  color: $pineGreen;
  font-size: 35px;
  font-weight: 300;
  text-transform: uppercase;
  animation-name: navTags;
  position: relative;
  animation-duration: 1.5s;
  animation-timing-function: ease-in;
  text-shadow: -6px 5px 32px $pineGreen;
}

.reportSubTitle {
  text-align: left;
  margin: 0 0 0.5rem 0;
  color: $labelDark;
  font-size: 20px;
  font-weight: 300;
  animation-name: navTags;
  position: relative;
  animation-duration: 1.5s;
  animation-timing-function: ease-in;
}

@keyframes navTags {
  from {
    right: 70px;
    opacity: 0;
  }
  to {
    right: 0px;
    opacity: 1;
  }
}

.pdfButton {
  border: solid 0.5px $labelDark;
  background-color: transparent;
  color: $labelDark;
  padding: 0.2rem 1rem;
  border-radius: 8px;
  margin-top: 1rem;
  transition: background-color 1s, box-shadow 1s;
  animation-name: navTags;
  position: relative;
  animation-duration: 1.5s;
  animation-timing-function: ease-in;
}

.pdfButton:hover {
  background-color: $mossGreen;
  color: $sand;
  box-shadow: -6px 5px 10px $pineGreen;
}

.classTitle {
  font-size: 25px;
  color: $mossGreen;
  padding: 0;
  font-family: $robotoMono;
}

.navCard {
  border-radius: 25px;
  padding: 4rem;
  z-index: 5;
  border: solid 1px $pineGreen;
  box-shadow: 1px 15px 15px $pineGreen;
  margin: 3rem auto;
  background-color: $darktablebg;
}

.toggleIcon {
  color: $pineGreen;
  border: solid 1px transparent;
  font-size: 35px;
  border-radius: 9px;
  transition: all 1s;
}

.toggleIcon:hover {
  cursor: pointer;
  border: solid 1px $pineGreen;
  padding-left: 1rem;
}

.title {
  display: inline;
  font-size: 22px;
  font-weight: 300;
  font-family: $inter;
  color: $pineGreen;
}

.conditionalContainerClasses {
  border: solid 1px $pineGreen;
  margin: 2rem 0; 
  padding: 1rem 2rem;
  border-radius: 8px;
  box-shadow: 1px 10px 10px $pineGreen;
}

.tradingLogsTitle {
  border: solid 1px $pineGreen; 
  border-radius: 8px;
  padding: .5rem 1rem;
  font-size: 14px;
  margin: 0 .8rem;
  color: $mossGreen;
  text-align: center;
}