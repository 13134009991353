@import "../../../styles/Forms.scss";

.currentCurrencyDark {
  font-weight: 900;
  color: #f0f0f0;
  font-size: 20px;
}

.currentCurrency {
  font-weight: 900;
  font-size: 20px;
  color: #34495e;
}

.selectInputButton {
    margin-left: -4rem;
    margin-top: .5rem;
    position: absolute;
    background: $pineGreen;
    border: solid 1px $pineGreen;
    color: $sand;
    font-size: 12px;
    padding: .3rem .5rem;
    border-radius: 8px;
}

.selectInputButton:hover {
    background: transparent;
}

.selectedQuotes {
  border: solid 1px $pineGreen;
  border-radius: 10px;
  margin: 1rem 2rem;
  padding: 0.2rem 1.5rem;
  display: inline-block;
  font-size: 13px;
  cursor: pointer;
}

.selectedQuotes:hover {
  border-color: $warningError;
  color: $sand;
}

.removeIcon {
  margin-left: 2rem;
  cursor: pointer;
  color: $warningError;
}