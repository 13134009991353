@import "../../styles/colors.scss";
@import "../../styles/typography";
@import "../../styles/Forms.scss";
@import "../../styles/AllEntities.scss";

.mainChartsDashboard {
    background: #FFFFFF;
    padding: 1rem 5rem; 
    border-radius: 20px; 
    border: solid 3px #6D9E93;
    width: 90%;
    margin: auto;
}

@media screen and (max-width: 1200px) {
    .mainChartsDashboard {
        padding: 2rem .5rem;
    }
}

.backIcon {
    color: $pineGreen;
    font-size: 40px;
    margin-top: 40px;
    margin-bottom: -40px;
}

@mixin fundsButtons {
    background-color: transparent;
    width: 100%;
    margin: 1rem;
    color: $sand;
    border-radius: 9px;
    border: solid 1px $pineGreen;
    padding: .5rem ;
    display: inline-block;
    font-size: 14px;
}

.fundButtonInactive:hover {
    background-color: $pineGreen;
}

.fundButtonInactive {
    @include fundsButtons()
}

.fundButtonActive {
    @include fundsButtons();
    background-color: $pineGreen;
}

.downloadIcon {
    font-size: 30px;
    color: $mossGreen;
    margin-bottom: 1rem;
}

.downloadContainer {
    border: solid 1px $pineGreen;
    width: 11rem;
    padding: .3rem .5rem;
    border-radius: 8px;
    margin: 2rem 0 1rem 0;
}

.downloadContainer span {
    color: $pineGreen;
    margin-left: .5rem;
    font-size: 12px;
}

.backIcon:hover, .downloadContainer:hover {
    cursor: pointer;
}

.tableTitle {
    color: $darkGreen;
    font-family: $larkenLight;
    font-size: 18px;
    text-align: center;
    height: 2.3rem;
}

@media screen and (max-width: 1650px) {
    .tableTitle {
        margin-top: 2rem;
    }
}

@media screen and (min-width: 1400px) {
    .tableTitle {
        font-size: 16px;
        margin-top: 0;
        margin-bottom: 1rem;
    }
}


@media screen and (min-width: 1400px) {
    .tableTitle {
        font-size: 16px;
        margin-top: 0;
        margin-bottom: 1rem;
    }
}

.tablesTitle {
    color: $darkGreen;
    text-align: center;
    font-size: 30px;
    font-family: $larkenMedium;
}


.tableContent {
    font-family: $larkenLight;
    font-weight: 200;
    color: $darkGreen;
    font-size: 18px;
    margin-bottom: 8px;
}

.tableContent p {
    font-family: $ingramMonoRegular;
    margin-bottom: 8px;
}

@media screen and (max-width: 1450px) {
    .tableContent p {
        font-size: 15px;
    }
    .tableContent {
        font-size: 15px;
    }
}

.fundTitle {
    font-size: 33px;
    color: $forestGreen;
    text-align: center;
    font-weight: 600;
}

.titleDates {
    font-size: 18px;
    color: $forestGreen;
    text-align: center;
    margin: 0 0 2rem 0;
}

.alertMessage {
    color: $warningError;
    font-weight: 800;
    font-size: 11px;
}

@mixin benchmarkTable($color) {
    background-color: rgba($color: $color, $alpha: 0.5);
    border-radius: 5px;
    padding: 0.9rem;
    color: $pineGreen;
    font-weight: 900;
    text-align: center;
    white-space: nowrap;
    overflow: hidden; 
    text-overflow: ellipsis; 
    margin: 0;
} 

.col1 {
    @include benchmarkTable($sand);
    text-align: end;
    padding-right: 2rem;
    margin-top: 1.3rem;
}

.col2 {
    @include benchmarkTable($forestGreen)
}

.col3 {
    @include benchmarkTable($mossGreen)
}

.col4 {
    @include benchmarkTable($pineGreen)
}

.col5 {
    @include benchmarkTable($neon)
}

.theader th {
    font-size: 17px;
    font-family: $larkenMedium;
}

.tableStrips td {
    font-size: 13px;
    font-family: $larkenLight;
    color: $charcoal !important;
}

.formInputs {
    display: inline-block;
    width: 100%;
    margin: 3rem 0 5rem 0;
}

.formInputs input {
    border: solid 1px $pineGreen;
    border-radius: 8px;
}

.chartsContainer {
    border-radius: 20px;
    margin: 2rem 0;
}

.tableContainer {
    border-radius: 20px;
    padding: 2rem 0;
    margin: 2rem 0;
    justify-content: center;
}

.tablesTitle {
    color: $pineGreen;
    text-align: center;
    margin: 2rem 0;
}

.chartTitle {
    color: $pineGreen;
    text-align: center;
    margin: 2rem;
    display: inline-block;
    font-size: 32px;
}

@media screen and (max-width: 1450px) {
    .chartTitle {
        font-size: 25px;
    }
}

// Download Files Dropdown
.dropdown {
    display: inline-block;
    position: relative;
}

.dropdown button {
    border: solid 1px $pineGreen;
    background-color: transparent;
    border-radius: 8px;
    width: 100%;
    height: 2.5rem;
    font-size: 12px;
    color: $charcoal;
    text-align: left;
    padding: 0.5rem 2rem;
}

.dropdownContent {
    display: none;
    position: absolute;
    width: 10rem;
    background-color: #FFFFFF;
    overflow-y: scroll;
    border: solid 1px $label;
    border-radius: 7px;
    z-index: 10;
    text-align: left;
    padding: 1rem;
    font-size: 13px;
    user-select: none;
    -webkit-user-select: none;
}


.dropdown p {
    font-size: 11px;
    transition: padding-left 1s;
    color: $charcoal;
}

.dropdown:hover .dropdownContent {
    display: block;
}

.dropdown button:hover {
    cursor: pointer;
}

.dropdown p:hover {
    cursor: pointer;
    padding-left: .2rem;
    font-weight: 800;
    color: $pineGreen;
    text-decoration: underline;
}

.downloadCSVLink {
    color: $pineGreen;
    font-size: 15px;
    margin: 1rem 0;
    position: relative;
    text-align: end;
    float: right;
    width: 10rem;
}

.downloadCSVLink:hover {
    font-weight: 800;
}

.portfolioComparisonText {
    color: $charcoal;
    font-size: 14px;
    font-weight: 100;
    font-style: italic;
}