@import "../../../styles/colors";
@import "../../../styles/typography";

.navTooltip {
  background-color: $darktablebg;
  color: $labelDark;
  text-align: center;
  border-radius: 6px;
  padding: 0.2rem 1.5rem;
  font-size: 12px;
  border-style: solid;
  border-width: 0.5px;
  border-color: $label;
  font-family: $robotoMono;
}

.navTooltip p { 
    margin: 0.2rem;
}

.zoomOutButton { 
  background-color: transparent;
  color: white;
  border-style: solid;
  border-color: $pineGreen;
  border-radius: 25px;
  border-width: 1px;
  padding: 0.5rem 1rem;
  margin: 1rem 0;
  font-size: 13px;
}

.zoomOutButton:hover {
  background-color: $pineGreen;
}

.tableTitle {
  color: $pineGreen;
  margin: 3rem 0 2rem 0;
  font-weight: bold;
  font-size: 25px;
  text-align: center;
}

tspan {
	font-size: 11px !important;
	color: black !important;
	font-family:  $robotoMono;
}