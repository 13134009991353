@import "../../../../styles/colors";
@import "../../../../styles/Forms";

.blockchainSubmitButton {
  border-radius: 10px;
  font-size: 12px;
  padding: 0.8rem 2rem;
  margin: 2rem 0 0 0;
  border: solid 1px $pineGreen;
  background: $pineGreen;
  color: $sand;
}

.blockchainSubmitButton:hover {
  background-color: $mossGreen;
}

.combinersTitle {
  margin-left: 4rem;
  color: $pineGreen;
  font-weight: 600;
  text-align: left;
}


.explorersContainer {
  height: 40rem; 
  overflow: auto; 
  padding: 2rem; 
  border: solid 1px $pineGreen;
  border-radius: 10px;
}

.transactionCard {
  border: solid 1px $pineGreen;
  border-radius: 9px;
  padding: 0.5rem 0;
  opacity: 1 !important;
  margin: 1rem 0;
}

.transactionCard:hover {
  cursor: grab;
  background-color: $pineGreen;
  color: $sand;
}

.transactionCard:hover .dataText {
  color: $sand;
}

.transactionCard:hover .tableDate p {
  color: $sand;
}

.transactionCard p {
  margin: 0;
}

.addCombinations {
  background-color: transparent;
  padding: 5px 10px;
  border-radius: 6px;
  border: none;
  color: $pineGreen;
  font-size: 40px;
  position: absolute;
  left: 30%;
  top: 40%;
  width: 2rem;
}

.transactionIcon {
  color: $pineGreen;
  font-size: 35px;
  margin: 20rem auto;
  position: absolute;
  left: 40%;
}


// Combinations

.combinationsHeader {
  position: sticky;
  display: flex;
  justify-content: flex-start;
  padding: 3rem 0;
  background-color: $darktablebg;
}

.emptyDropContainer {
  border-radius: 10px;
  color: $warningError;
  border: dashed 1px $warningError;
  padding: .5rem 0;
  vertical-align: middle;
  height: 9.5rem;
}

.emptyIcon {
  font-size: 40px;
}

.fullDropContainer {
  border-radius: 10px;
  color: $mossGreen;
  border: dashed 1px $mossGreen;
  padding: .5rem 0;
  vertical-align: middle;
}

.removeCombination {
  color: $warningError;
  font-size: 20px;
  margin-top: 2.5rem;
  line-height: 4rem;
}

.removeCombination:hover {
  cursor: pointer;
}

.submitButton {
  padding: 0.5rem 10rem;
  border: none;
  background-color: transparent;
  border: solid 1px $pineGreen;
  color: $mossGreen;
}

.dataText {
  color: $pineGreen;
  font-size: 15px;
}

.dataText span {
  color: $labelDark;
  font-size: 15px;
}

.tableDate {
  padding: 0.5rem;
  font-size: 10px;
  display: inline-block;
}

.tableDate p {
  margin: 0;
  font-size: 15px;
  color: $pineGreen;
  font-weight: 900;
}

.ticker h5 {
  font-size: 15px;
}

