.carousel-control-prev,
.carousel-control-next {
  position: fixed;
  transform: translateY(-50%);
  height: 5rem;
  margin-top: 1rem;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: none; /* Hide the default icons to prevent duplication */
}

.carousel-control-prev-icon:hover,
.carousel-control-next-icon:hover {
    cursor: pointer;
}
