@import "../../styles/colors";
@import"../../styles/typography";

* {
  font-family: $inter;
}

*:focus {
  outline: none;
}

select {
  -moz-appearance: none; /* Disable default Firefox styling */
  appearance: none; /* Disable default styling for other browsers */
  background: transparent; /* Set background to transparent to remove the dropdown arrow in Firefox */
  padding: 0.5rem; /* Adjust padding as needed */
  border: 1px solid $pineGreen; 
  border-radius: 8px; /* Add your desired border-radius */
}

/* Style the select inputs as desired */
select:focus {
  outline: none; /* Remove focus outline */
  border-color: $pineGreen; /* Change border color on focus */
}

select::-ms-expand {
  display: none; 
}

select option {
  font-family: Arial, Helvetica, sans-serif
}


// .main {
//   // padding: 0.25rem;
//   // overflow-x: hidden; // To show background color dynamically to the bottom
// }

@mixin sidebarMixin {
  border-right-style: solid;
  border-width: 0.8px;
  justify-content: center;
  height: 100vh;
  padding-right: 1rem;
  position: fixed;
  z-index: 5;
  width: 13%;
  transition: .6s;
  overflow-y: auto;
  overflow-x: hidden;
  // box-shadow: 1px 15px 15px grey;
}

@mixin sideLinksMixin {
  font-size: 13px;
  text-align: left;
  padding: .8rem 0;
  margin-left: 1rem;
  display: inline-block;
  text-decoration: none;
  z-index: 1;
  user-select: none;
}

.subLinkDark {
  font-family: $inter;
  color:#cfcfcf;
  text-decoration: none;
  font-size: 13px;
  font-weight: 100;
  user-select: none;
  display: block;
  padding: .5rem 0 .5rem 5rem;
}

.subLinkDark:hover {
  color:#cfcfcf;
  font-weight: 900;
}

.subcontainerIcon {
  color:#cfcfcf;
  float: right;
  font-size: 35px;
}

.subcontainerIcon:hover {
  cursor: pointer;
}

.toggleSubcontainerButton {
  position: fixed;
  border: solid 1px $pineGreen;
  background-color: $darktablebg;
  color: $sand;
  border-radius: 5px;
  padding: .2rem .5rem;
  left: 3%;
}

.subcontainerOnToggle {
  position: fixed;
  border: solid 1px $pineGreen;
  background: $darktablebg;
  opacity: 1;
  left: 4%;
  width: 15rem;
}

// darkMode
.mainBackgroundDark {
  background: rgb(20,26,47);
  background: linear-gradient(90deg, rgba(20,26,47,1) 25%, rgba(50,70,81,1) 100%, rgba(14,53,49,1) 100%);
  overflow-y: auto;  // It adapts the screen to the content size with a dynamic scrolling
  overflow-x: hidden;
}

@media screen and (min-width: 1400px) {
  .outletStyle {
    padding: 0 3% 0 15%; 
  }
}

@media screen and (max-width: 1399px) {
  .outletStyle {
    padding: 0 5%;
  }
}

.outletStyle {
  height: 100vh;
  width: 100vw;
  transition: .6s;
}

.sidebarDark {
  @include sidebarMixin;
  background: rgb(20,26,47);
  background: linear-gradient(75deg, rgba(20,26,47,1) 35%, rgba(50,70,81,1) 100%, rgba(14,53,49,1) 50%);
}

.sideLinksDark {
  @include sideLinksMixin;
  color: $labelDark;
}

.toggleButtonDark {
  border-radius: 35px;
  margin: 0 2rem;
  padding: 0 2rem;
  border-color: $pineGreen;
  border-width: 1px;
  border-style: solid;
  color: $pineGreen;
  background-color: transparent;
}

.toggleButtonDark:hover {
  border-width: 2px;
}

.iconsDark {
  font-size: 23px;
  color:#cfcfcf;
  margin-right: 0.1rem;
}

// End of DarkMode

.sidebar {
  @include sidebarMixin;
  background-color: white;
  border-color: #cfcfcf;
}

.sideLinks {
  @include sideLinksMixin;
  color: #858585;
}

.sideLinks:hover, .sideLinksDark:hover  {
  color: #6d9e93;
  font-weight: 900;
}

.toggleButton {
  border-radius: 35px;
  margin: 0 2rem;
  padding: 0 2rem;
  border-style: solid;
  color: white;
  border-color: #5c5b5b;
  border-width: 1px;
  color: #5c5b5b;
}

.toggleButton:hover {
  border-width: 2px;
}

.mainBackground {
  background-color: #fcf9f9;
  height: 100%;
}

.mainIcon {
  user-select: none;
  font-family: $inter;
  transition: padding .6s;
}

.icons {
  font-size: 23px;
  color: #6d9e93;
  margin-right: 0.1rem;
}

.navbarToggle {
  margin-right: 2rem;
}

.sideNavLinks {
  margin-left: 2rem;
  // font-weight: 600;
  padding: 0.5rem;
  animation-name: links-animation;
  position: relative;
  animation-duration: 1s;
  animation-timing-function: ease-in;
}

@keyframes links-animation {
  from {
    left: -90px;
    opacity: 0;
  }
  to {
    left: 0px;
    opacity: 1;
  }
}

.navLinks {
  margin-left: 2rem;
  font-weight: 700;
}

.navBarLinks {
  position: absolute;
  right: 0;
  margin-right: 2rem;
  font-weight: 700;
}

.navEmail {
  font-weight: 100;
}

.devEnvAlert {
  font-weight: 300;
  color: $khaki;
  font-size: 12px;
  margin: 0.5rem 0;
}

@mixin display-sidebar-button($bgColor) {
  z-index: 10;
  color: $pineGreen;
  margin: 0;
  position: fixed;
  top: 50%;
  background-color: $bgColor;
  border: solid 1px $pineGreen;
  padding: .5rem;
  border-radius: 50%;
  transition: left .6s;
}

.sidebarButtonDark {
  @include display-sidebar-button($darktablebg)
}

.sidebarButton {
  @include display-sidebar-button(transparent)
}

.sidebarButton:hover, .sidebarButtonDark:hover {
  border-width: 2px;
  background-color: $pineGreen;
}

.toggleIcon {
  font-size: 25px;
  color: $khaki;
}

// Tooltips Icons 
@mixin tooltip-mixin($verticalPadding) {
  visibility: hidden;
  background-color: #fcf9f9;
  color: #2f3e46;
  text-align: center;
  border-radius: 6px;
  padding: $verticalPadding 2rem;
  font-size: 11px;
  font-weight: 300;
  opacity: 0.9;
  position: fixed;
  z-index: 5;
  left: 5%
}

.tooltip .tooltiptext {
  @include tooltip-mixin(0.5rem);
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}