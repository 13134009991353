@import "../.././styles/Forms.scss";

.currentCurrencyDark {
  font-weight: 900;
  color: #f0f0f0;
  font-size: 20px;
}

.currentCurrency {
  font-weight: 900;
  font-size: 20px;
  color: #34495e;
}
