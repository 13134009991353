@import "../../../styles/AllEntities.scss";
@import "../../../styles/Forms.scss";

.tableInput {
    border-radius: 5px;
    width: 40%;
    background-color: transparent;
    border: solid 1px $pineGreen;
    color: $labelDark;
    text-align: center;
    padding: 0.2rem;
    height: 100%;
}

@mixin editModeButtons($borderColor) {
    border: solid 1px $borderColor;
    border-radius: 8px;
    background-color: transparent;
    font-size: 15px;
    width: 2rem;
    margin: 0 .2rem
}

.checkSymbol {
    @include editModeButtons($pineGreen);
    color: $pineGreen;
}

.XSymbol {
    @include editModeButtons($warningError);
    color: $warningError
}

.editButton {
    background-color: transparent;
    border: solid 1px $pineGreen;
}
