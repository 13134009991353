@import "../.././styles/Forms.scss";

.currencyPopupContainer {
    padding: .2rem; 
    border: solid 1px #6D9E93;
    border-radius: 15px;
    height: 4rem;
}

.currencyPopupText  {
    color: $pineGreen;
    text-align: center;
}

.currencyPopupText span {
    font-size: 12px;
}

.currencyPopupText p {
    color: $labelDark;
    font-size: 13px;
}

.importButton {
    background: $pineGreen;
    border: solid 1px $pineGreen;
    border-radius: 10px;
    padding: .5rem 3rem;
    color: $sand;
    font-size: 11px;
    margin: 2rem 0 0 0;
}

.title {
    color: $pineGreen;
    font-size: 22px;
    margin: 1rem 0 2rem 0;
}

.removeButton {
    margin-left: -2rem;
    margin-top: .6rem;
    position: absolute;
    background: transparent;
    color: $label;
    border: none;
    font-size: 15px;
    font-weight: 900;
    animation-name: formInputs;
    position: relative;
    animation-duration: .5s;
    animation-timing-function: ease-in;
  } 
  
.removeButton:hover {
    color: $pineGreen;
}

.selectInputButton {
    margin-left: -4rem;
    margin-top: .5rem;
    position: absolute;
    background: $pineGreen;
    border: solid 1px $pineGreen;
    color: $sand;
    font-size: 12px;
    padding: .3rem .5rem;
    border-radius: 8px;
}

.selectInputButton:hover {
    background: transparent;
}

.formTitle {
    font-size: 25px;
    color: $pineGreen;
}

.formTitle span {
    color: $label
}

.fundFormInputs {
    height: 1rem;
}
  