@import "../../../styles/colors";
@import "../../../styles/typography";

.mainContainer {
  margin: 2rem 0 4rem 0;
}
.mainChartBar {
  width: 100%;
  height: 3rem;
  margin-bottom: 1rem;
  // background-image: repeating-linear-gradient(135deg, #6D9E93 0, #1b2236 0.1px, transparent 0, transparent 50%);
  // background-size: 10px 10px;
  // background-color: #2f4858;
  border-style: solid;
  // background: rgb(27,34,54);
  // background: linear-gradient(262deg, rgba(27,34,54,1) 7%, rgba(109,158,147,1) 49%, rgba(27,34,54,1) 95%); 
  border-width: 1px;
  border-color: $pineGreen;
  border-radius: 12px;
  animation-name: main-bar;
  animation-duration: 1.5s;
  position: relative;
  opacity: 0.8;
}

.layerName {
  text-align: center;
  color: $labelDark;
  font-weight: 900;
  font-size: 15px;
}

@keyframes main-bar {
  from {
    transform: scaleX(0);
  }
  to {
    transform: scaleX(1);
  }
}

@mixin chart-bars($backgroundColor, $topMargin) {
  height: 3rem;
  background-color: $backgroundColor;
  margin: $topMargin auto -3rem auto;
  animation-name: main-bar;
  animation-duration: 3s;
  border-radius: 5px
}

.highRange {
  @include chart-bars(#4F7A7F, 0);
}

.lowRange {
  @include chart-bars(#2F4858, 0);
}

.aimPercentage {
  @include chart-bars(none, 0);
  border-style: solid;
  border-top: none;
  border-bottom: none;
  border-color: #caccbd;
  border-width: 5px;
}

.currentPercentage {
  height: 1rem;
  background-color: rgb(86, 101, 152, 0.5);
  margin: 1rem auto -3rem auto;
  border-radius: 5px;
}

.tooltiptext {
  font-family: $robotoMono;
  visibility: hidden;
  background-color: $darktablebg;
  color: $labelDark;
  text-align: center;
  border-radius: 6px;
  padding: 0.2rem 1.5rem;
  font-size: 12px;
  border-style: solid;
  border-width: 1px;
  position: absolute;
  bottom: 2rem;
  z-index: 5;
  border-style: solid;
}

:where(.highRange:hover, .lowRange:hover, .aimPercentage:hover, .currentPercentage:hover)
  .tooltiptext {
  visibility: visible;
}
