@import "../../../styles/AllEntities.scss";
@import "../../../styles/colors";
@import "../../../styles/typography";

.tableTitle {
  color: $pineGreen;
  margin: 3rem 0 2rem 0;
  font-weight: 300;
  font-size: 30px;
  text-transform: uppercase;
  text-shadow: -6px 2px 32px rgba(109,158,147,1);
}

.tooltip {
  position: relative;
  // display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  background-color: #fcf9f9;
  color: #2f3e46;
  text-align: center;
  border-radius: 6px;
  padding: 0.2rem 1.5rem;
  font-size: 10px;
  font-weight: 200;
  opacity: 0.9;
  position: absolute;
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.toggleIcon {
  color: $pineGreen; 
  font-size: 25px;
  text-align: center;
  display: block;
}

.toggleIcon:hover {
  cursor: pointer;
}

.collapsibleContainer {
  width: 70%;
  margin: 0 auto;
  padding: 3rem;
}

.collapsibleTableHeader * {
  color: $pineGreen;
  text-align: center;
  font-size: 12px;
  font-family: $robotoMono;
  font-weight: 900;
  margin: 1rem 0;
}

.collapsibleRow * {
  color: $labelDark;
  text-align: center;
  font-weight: 900;
  font-size: 12px;
  margin: 1rem 0;
}

.collapsibleRow:hover * {
  color: $pineGreen;
}

.hideShowMessage {
  color: $pineGreen;
  text-align: left;
  font-size: 12px;
}

