@import "../.././styles/AllEntities.scss";
@import "../.././styles/Forms.scss";
@import "../.././styles/colors";

$bgColor: #6D9E93;

.progressColor {
  --bs-progress-bar-bg: $bgColor;
}

.bgWithColor {
    background-color: $bgColor;
}