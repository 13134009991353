@import "../.././styles/Forms.scss";

@mixin select-input( $fontsColor, $selectColor) {
  background-color: transparent;
  color: $fontsColor;
  padding: 0.4rem 1rem;
  width: 100%;
  // margin-bottom: 1rem;
  border-radius: 10px;
  border-color: $pineGreen;
  font-size: 13px;
  height: 2.8rem;
  color: $selectColor;
  animation-name: createButtonAnimation;
  position: relative;
  animation-duration: 1.5s;
  animation-timing-function: ease-in;
}

@keyframes createButtonAnimation {
  from {
    right: 100px;
    opacity: 0;
  }
  to {
    right: 0px;
    opacity: 1;
  }
}

.selectInputDark {
  @include select-input( white, #b6b6b6);
}

.selectInput {
  @include select-input(black, #858585);
}

.selectInput:hover {
  cursor: pointer;
}

.selectInputDark:hover {
  cursor: pointer;
}

.addIcon {
  background-color: #6d9e93;
  padding: 0.5rem 1rem;
  margin-top: 2.5rem;
  text-decoration: none;
  color: white;
  border-radius: 25px;
  font-weight: 500;
  float: right;
  font-size: 12px;
}

.addIcon:hover {
  color: white;
  background-color: #738a84;
}

.calendarInput {
  border-style: solid;
  border-color: #cfcfcf;
  padding: 1.4rem 1.2rem;
}

// Radio inputs
.container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border-radius: 50%;
}

.container:hover input ~ .checkmark {
  background-color: #ccc;
}

.container input:checked ~ .checkmark {
  background-color: #6d9e93;
}

.decimalsNotice {
  font-size: 12px;
  margin-top: 0.5rem;
  color: #404040;
}

.decimalsNoticeDark {
  font-size: 12px;
  margin-top: 0.5rem;
  color: #cfcfcf;
}

.radioLabel {
  color: #34495e;
  margin-top: 3rem;
  font-weight: 900;
  margin-bottom: 2rem;
  font-size: 13px;
  text-align: left;
}

.radioLabelDark {
  color: #f0f0f0;
  margin-top: 3rem;
  font-weight: 900;
  margin-bottom: 2rem;
  font-size: 13px;
  text-align: left;
}

@media only screen and (min-width: 400px) {
  .radiosRow {
    padding: 0 5rem;
    margin-bottom: 3rem;
  }
  .createButton {
    margin-left: 3rem;
  }
}

@media only screen and (min-width: 760px) {
  .radiosRow {
    padding: 0 20rem;
  }
}

@media only screen and (min-width: 800px) {
  .radiosRow {
    padding: 0 15rem;
    margin-bottom: 3rem;
  }
  .createButton {
    margin-left: 15rem;
  }
}

.initialMessage {
  font-style: italic;
  color: #34495e;
  margin-top: 3rem;
  font-weight: 900;
  margin-bottom: 2rem;
  font-size: 13px;
  animation-name: initialMessageAnimation;
  position: relative;
  animation-duration: 1.5s;
  animation-timing-function: ease-out;
}

.initialMessageDark {
  font-style: italic;
  color: #f0f0f0;
  margin-top: 3rem;
  font-weight: 900;
  margin-bottom: 2rem;
  font-size: 13px;
  animation-name: initialMessageAnimation;
  position: relative;
  animation-duration: 1.5s;
  animation-timing-function: ease-out;
}

@keyframes initialMessageAnimation {
  from {
    right: 100px;
    opacity: 0;
  }
  to {
    right: 0px;
    opacity: 1;
  }
}

.initialMessageContainer {
  position: relative;
  animation: fadeOutMessage 3s linear 0s normal forwards;
}

@keyframes fadeOutMessage {
  from {
    right: 0px;
    opacity: 1;
  }
  to {
    right: 100px;
    opacity: 0;
  }
}

.textarea {
  background-color: transparent;
  border-radius: 10px;
  border: solid 0.5px $pineGreen;
  font-size: 14px;
  color:$khaki;
  padding-top: 0.5rem;
  padding-left: 0.8rem;
  padding-right: 0.8rem;
  animation-name: formInputs;
  position: relative;
  animation-duration: 1.5s;
  animation-timing-function: ease-in;
}