@import "../.././styles/Forms.scss";

.currentCurrencyDark {
  font-weight: 900;
  color: #f0f0f0;
  font-size: 20px;
}

.currentCurrency {
  font-weight: 900;
  font-size: 20px;
  color: #34495e;
}

.selectInputButton {
    margin-left: -4rem;
    margin-top: .5rem;
    position: absolute;
    background: $pineGreen;
    border: solid 1px $pineGreen;
    color: $sand;
    font-size: 12px;
    padding: .3rem .5rem;
    border-radius: 8px;
}

.selectInputButton:hover {
    background: transparent;
}