@mixin select-input($backgroundColor, $fontsColor, $selectColor) {
    background-color: $backgroundColor;
    color: $fontsColor;
    padding: 0.4rem 1rem;
    width: 100%;
    // margin-bottom: 1rem;
    border-radius: 15px;
    border-color: rgb(207, 207, 207);
    font-size: 13px;
    height: 3rem;
    color: $selectColor;
    animation-name: createButtonAnimation;
    position: relative;
    animation-duration: 1.5s;
    animation-timing-function: ease-in;
  }
  
  @keyframes createButtonAnimation {
    from {
      right: 100px;
      opacity: 0;
    }
    to {
      right: 0px;
      opacity: 1;
    }
  }
  
