@import "../.././styles/Forms.scss";
@import "../.././styles/colors";

// CryptosDashboard Component
.textareaInput {
  border-radius: 15px;
  padding: 1rem;
}

.createCryptoButton {
  background-color: $pineGreen;
  padding: 0.4rem 2rem;
  border: none;
  border-radius: 10px;
  font-size: 12px;
  margin-right: 2rem;
}

.importListingsButton {
  background-color: transparent;
  color: #ffffff;
  padding: 0.4rem 3rem;
  border: solid 1px $pineGreen;
  border-radius: 10px;
  font-size: 12px;
}

.importListingsButton:hover {
  background-color: $pineGreen;
  font-weight: 700;
}

.linktoCreateCrypto {
  text-decoration: none;
  color: #ffffff;
}

.linktoCreateCrypto:hover {
    color: #ffffff;
    font-weight: 700;
}

.dashboardButtonsWrapper {
    margin-bottom: 5rem; 
    display: flex; 
    justify-content: flex-start;
}

@media screen and (max-width: 480px) {
    .createCryptoButton, .importListingsButton {
      margin-top: 2rem;
    }
    .createCryptoButton {
        margin-right: 0;
    }
    .dashboardButtonsWrapper {
        display: block;
    }
    .fundFormInputs {
        margin: 0;
    }
  }