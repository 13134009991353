@import "../../styles/colors";

.buttonStyle {
    background-color: transparent;
    padding: .5rem 2rem;
    border: solid 1px $pineGreen;
    border-radius: 8px;
    color: $sand;
    font-size: 12px;
}

.buttonStyle:hover {
    background-color: $pineGreen;
}