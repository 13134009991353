@import "../styles/colors";
@import "../styles/typography";

// DarkMode
.cardTableDark {
  background-color: $darktablebg;
  border-radius: 25px;
  // border-style: solid;
  border-width: 0.1px;
  border-color: $pineGreen;
  padding: 3rem;
  text-align: center;
  z-index: 5;
  box-shadow: 1px 0px 25px $pineGreen;
  padding-bottom: 15rem;
  margin-bottom: 3rem;
  opacity: 0.8;
}

.mainTitleDark {
  color: $pineGreen;
  letter-spacing: 0.1rem;
  margin: 0.5rem 0 2rem 0;
  font-weight: bold;
  font-size: 30px;
  font-family: $inter;
}

.subTitleDark {
  font-family: $inter;
  color: $labelDark;
  margin: 1rem 0 0 0;
  font-weight: 300;
  font-size: 25px;
}

//End of DarkMode

.cardTable {
  background-color: white;
  border-radius: 25px;
  border-style: solid;
  border-width: 4px;
  border-color: #f0f0f0;
  padding: 3rem;
  text-align: center;
  z-index: 5;
  box-shadow: 1px 15px 15px grey;
  padding-bottom: 15rem;
  margin-bottom: 3rem;
}

.createButton {
  margin-bottom: 3rem;
  border-radius: 8px;
  padding: 0.8rem 2rem;
  background-color: $pineGreen;
  text-decoration: none;
  color: white;
  display: table;
  font-size: 14px;
}

.createButtonDark {
  margin-bottom: 3rem;
  border-radius: 8px;
  padding: 0.5rem 2rem;
  background-color: transparent;
  text-decoration: none;
  color: white;
  display: table;
  border-style: solid;
  border-color: #6d9e93;
  border-width: 1px;
  font-size: 14px;
}

.createButtonDark:hover,
.createButton:hover {
  background-color: #a5c6ac;
  color: #f0f0f0;
  font-weight: 600;
}

@media only screen and (max-width: 600px) {
  .createButton {
    margin: 2rem auto;
  }
}

.mainTitle {
  color: #858585;
  margin: 0.5rem 0 2rem 0;
  font-weight: bold;
  font-size: 35px;
}

.subTitle {
  color: #b6b6b6;
  margin: 1rem 0 0 0;
  font-weight: bold;
  font-size: 25px;
}

.alertMessage {
  text-align: center;
  margin-top: 5rem;
}
