@import "../.././styles/AllEntities.scss";
@import "../.././styles/colors";

.detailButtonDark {
  text-decoration: none;
  color: $mossGreen;
  padding: .3rem;
  border-radius: 8px;
  font-weight: 100;
  font-size: 20px;
  width: 4rem;
  display: block;
  text-align: center;
  margin: 1rem auto;
  transition: background-color 1.2s;
}

.detailButtonDark:hover {
  background: rgba(109, 158, 147, .15);
  color: $mossGreen;
}

@mixin info-card($backgroundColor, $boxShadowColor) {
  background-color: $backgroundColor;
  border-radius: 15px;
  z-index: 3;
  box-shadow: 1px 11px 25px $boxShadowColor;
  margin: 2rem auto;
  padding: 2rem;
  opacity: 0.9;
  position: relative;
  // animation-name: infoCard;
  // animation-duration: 1.5s;
  // animation-timing-function: ease-in;
}

@keyframes infoCard {
  from {
    right: 70px;
    opacity: 0;
  }
  to {
    right: 0px;
    opacity: 1;
  }
}

.headerCardDark h1 {
  text-align: left;
  color: $pineGreen;
  font-size: 23px;
  font-family: $inter;
}

.headerCardDark h2 {
  text-align: left;
  color: $label;
  font-size: 20px;
}

.headerCardDark {
  @include info-card($darktablebg, $pineGreen);
  height: 15rem;
  background: rgb(27,34,54);
  background: linear-gradient(240deg, rgba(27,34,54,1) 0%, rgba(27,34,54,1) 5%, rgba(61,84,79,0.3) 90%);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' version='1.1' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:svgjs='http://svgjs.dev/svgjs' width='700' height='400' preserveAspectRatio='none' viewBox='0 0 550 400'%3e%3cg mask='url(%26quot%3b%23SvgjsMask1071%26quot%3b)' fill='none'%3e%3cpath d='M37 400L437 0L887 0L487 400z' fill='url(%26quot%3b%23SvgjsLinearGradient1072%26quot%3b)'%3e%3c/path%3e%3cpath d='M496 400L96 0L-282 0L118 400z' fill='url(%26quot%3b%23SvgjsLinearGradient1073%26quot%3b)'%3e%3c/path%3e%3cpath d='M328.82182515528706 400L550 178.82182515528706L550 400z' fill='url(%26quot%3b%23SvgjsLinearGradient1072%26quot%3b)'%3e%3c/path%3e%3cpath d='M0 400L221.17817484471294 400L 0 178.82182515528706z' fill='url(%26quot%3b%23SvgjsLinearGradient1073%26quot%3b)'%3e%3c/path%3e%3c/g%3e%3cdefs%3e%3cmask id='SvgjsMask1071'%3e%3crect width='550' height='400' fill='white'%3e%3c/rect%3e%3c/mask%3e%3clinearGradient x1='0%25' y1='100%25' x2='100%25' y2='0%25' id='SvgjsLinearGradient1072'%3e%3cstop stop-color='rgba(109%2c 158%2c 147%2c 1)' offset='0'%3e%3c/stop%3e%3cstop stop-opacity='0' stop-color='rgba(109%2c 158%2c 147%2c 1)' offset='0.66'%3e%3c/stop%3e%3c/linearGradient%3e%3clinearGradient x1='100%25' y1='100%25' x2='0%25' y2='0%25' id='SvgjsLinearGradient1073'%3e%3cstop stop-color='rgba(109%2c 158%2c 147%2c 1)' offset='0'%3e%3c/stop%3e%3cstop stop-opacity='0' stop-color='rgba(109%2c 158%2c 147%2c 1)' offset='0.66'%3e%3c/stop%3e%3c/linearGradient%3e%3c/defs%3e%3c/svg%3e");
}

.headerCardDark:hover {
  cursor: pointer;
  opacity: 0.7;
  box-shadow: 1px 15px 30px $pineGreen;
}