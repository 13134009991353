@import "../../../styles/Forms.scss";

.textarea {
  background-color: transparent;
  border-radius: 10px;
  border: solid 0.5px $pineGreen;
  font-size: 14px;
  color: $khaki;
  padding-top: 0.5rem;
  padding-left: 0.8rem;
  padding-right: 0.8rem;
  animation-name: formInputs;
  position: relative;
  animation-duration: 1.5s;
  animation-timing-function: ease-in;
}
