@import "../.././styles/AllEntities.scss";
@import "../.././styles/colors.scss";
@import "../.././styles/typography.scss";
@import "../.././styles/mixins/checkbox.scss";

@mixin userConfirmation {
  font-size: 13px;
  padding: 0.5rem;
  background-color: transparent;
  font-weight: 900;
  text-align: left;
  margin: 0
}

.confirmed {
  @include userConfirmation();
  color: $pineGreen;
}

.notConfirmed {
  @include userConfirmation();
  color: $warningError;
}


.emailFonts {
  font-size: 14px;
  text-align: left;
  margin: 0.5rem 0;
  color: $pineGreen;
  font-weight: 600;
}

.stickyNotes {
  height: 2rem;
  display: inline-flex;
  margin: 0.5rem;
  border: solid 0.1px $mossGreen;
  padding: 5px 25px;
  border-radius: 8px;
  background-color: transparent;
  font-size: 12px;
  text-align: left;
  color: $mossGreen;
  z-index: 4;
  box-shadow: 0px 0px 15px $pineGreen;
}

.deleteXButton {
  margin-left: 0.6rem;
  font-weight: bolder;
  font-size: 20px;
  text-align: right;
  color: $mossGreen;
}

.deleteXButton:hover {
  cursor: pointer;
}

.selectInput {
  background-color: transparent;
  border: solid 1px $pineGreen;
  padding: 0.5rem 2rem;
  border-radius: 10px;
  color: $khaki;
  font-family: $roboto;
}

.options {
  color: $label;
  background-color: $darktablebg;
}

.dotIcon {
  font-size: 21px;
  margin-right: .3rem;
}

.icons {
  color: $pineGreen;
  font-size: 20px;
  display: inline;
}

.icons:hover {
  cursor: pointer;
  color: #738a84;
}

.tooltip {
  position: relative;
}

.tooltip .tooltiptext {
  visibility: hidden;
  background-color: #fcf9f9;
  color: #2f3e46;
  text-align: center;
  border-radius: 6px;
  padding: 0.2rem 1.5rem;
  font-size: 10px;
  font-weight: 200;
  opacity: 0.7;
  position: absolute;
  z-index: 1;
  top: -1rem;
  right: 0.5rem;
  min-width: 8rem;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

