@import "../../../styles/Forms.scss";
@import "../../../styles/colors";
@import "../../../styles/AllEntities.scss";

.title {
  font-size: 22px;
  background-color: $pineGreen;
  padding: 0.5rem 0;
  border-radius: 10px;
  color: $khaki;
  animation-name: averages-title;
  position: relative;
  animation-duration: 1.5s;
  animation-timing-function: ease-in;
  vertical-align: middle;
}

@keyframes averages-title {
  from {
    right: 70px;
    opacity: 0;
  }
  to {
    right: 0px;
    opacity: 1;
  }
}

.title span {
  color: $mossGreen;
  font-weight: 600;
}

.bookingTitle {
  font-size: 18px;
  color: $khaki;
  border-bottom: solid 1px $mossGreen;
  padding: 0.5rem 0;
  font-weight: 600;
}

.periodContainer {
  border: solid 1px $mossGreen;
  margin: 3rem 0;
  border-radius: 10px;
}

.averagesTable td {
  table-layout: fixed;
  width: 15rem;
}

.greenCell {
  background: #6d9e93;
  color: #ffffff;
}

.selectMessage {
  margin-top: 3rem;
  color: $pineGreen;
  font-size: 10rem;
}

.selectMessage h5 {
  font-size: 30px;
}
