@import "../.././styles/Dashboards.scss";

.fundOwnerLink {
    margin: 1rem 2rem;
    text-decoration: none;
    color: darkgrey;
    font-weight: 800;
    padding: 0.6rem 0.8rem;
    border-style: solid;
    border-radius: 25px;
    font-size: 11px;
}

.fundOwnerLink:hover {
    color: #6d9e93;
}