table th {
    position: sticky;
    top: 0;
    z-index: 1; // any positive value, layer order is global
    background: white; // any bg-color to overlap
    font-weight: bold;
}

:global {
    .table-responsive {
        overflow: initial !important;
    }
}
