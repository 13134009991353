@import "../styles/colors";
@import "../styles/typography";

@font-face {
  font-family: "Larken-Thin";
  src: local("Larken-Thin"),
    url("../fonts/hodl-fonts/Larken/OTF/Larken-Thin.otf");
}

body {
  font-family: $inter;
  margin: 0;
  padding: 0;
}

main {
  height: 100vh;
  background: rgb(20,26,47);
  background: linear-gradient(90deg, rgba(20,26,47,1) 25%, rgba(50,70,81,1) 100%, rgba(14,53,49,1) 100%);
  z-index: -3;
  overflow: auto;
}

.loginForm {
  margin-top: 200px;
}

input {
  border-radius: 10px;
  height: 45px;
  border: 1px;
  width: 100%;
  padding-left: 1rem;
}

input::placeholder {
  font-size: 14px;
}

.registerButton {
  margin: 0;
  background-color: $pineGreen;
  border-style: solid;
  border-width: 1px;
  border-color: $pineGreen;
  margin-top: 2rem;
  border-radius: 10px;
  padding: .7rem 0;
  display: block;
  width: 100%;
  color: white;
  font-size: 14px;
}

.registerButton:hover {
  background: #a5c6ac;
}

.block_background {
  position: absolute;
  height: 100%;
  bottom: 0;
  z-index: -2;
}

.codeMessage {
  color: $sand;
  text-align: center;
  font-family: $roboto;
  font-size: 15px;
  font-style: italic;
}

.link {
  text-decoration: none;
  color: white;
  margin: 2rem 0;
  font-size: 13px;
}

.link:hover {
  color: #a5c6ac;
  cursor: pointer;
}

.linkDiv {
  text-align: center;
  margin: 1rem 0 5rem 0;
  font-size: 13px;
}

.conditionsList {
  color: white;
  font-size: 0.8rem;
  margin-top: 1rem;
  font-style: italic;
}

.formErrorNotice {
  font-size: 13px;
  font-style: italic;
  color:#a5c6ac;
  margin-top: 0.2rem;
  text-align: center;
}

// MFA code input

.codeContainer {
  display: flex;
  justify-content: center;
}

.codeInput {
  text-align: center;
  border-radius: 5px;
  padding: 0;
}

.inputTitle {
  color: $sand;
  text-align: center;
  margin: 1rem 0;
  font-size: 22px;
}

.message {
  color: $sand;
  text-align: center;
  font-style: italic;
  font-size: 13px;
}