@import "../styles/colors";

.modal {
  position: fixed;
  z-index: 600;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  // overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.8);
}

.modalContent {
  background-color: $darktablebg;
  margin: .5% auto;
  padding: 3rem 1rem;
  border: solid 1px $pineGreen;
  border-radius: 10px;
  width: 80%;
  height: 100%;
  overflow: auto;
}

.close {
  color: $label;
  float: right;
  font-size: 32px;
  font-weight: 400;
  margin-top: -2rem;
}

.close:hover,
.close:focus {
  color: $mossGreen;
  text-decoration: none;
  cursor: pointer;
}

// Loading Mutation Component
.modalContent h1 {
  text-align: center;
  margin: 2rem 0;
  font-size: 20px;
}