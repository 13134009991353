@import "../../../styles/colors";
@import "../../../styles/typography";
@import "../../../styles/Forms.scss";

.textareaInput {
  border-radius: 15px;
  padding: 1rem;
  height: 13rem;
}

.submitButton {
    background-color: $pineGreen;
    color: #FFFFFF;
    border-radius: 10px;
}

.datetime {
    border-radius: 10px;
}

.inputLabelDark {
    color: $pineGreen;
    font-family: $inter;
    margin: 1rem 0 1.2rem 0
}

// Drag Drop Listings Import Modal
.dragDropContainer p {
    font-size: 13px;
    color: $labelDark;
}
.dragDropContainer:hover {
    cursor: pointer;
}

.thumbsContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: 'wrap';
    margin-top: 16;
};

.thumb {
    padding: 0.3rem;
}

.filePath {
    word-wrap: break-word;
    font-size: 12px;
}

.uploadIcon {
    margin: 1rem 0;
    font-size: 30px;
    border: solid 1px $pineGreen;
    color: $pineGreen;
    border-radius: 50%;
    padding: 0.1rem;
}

.chooseButton {
    margin: 1rem;
    padding: 0.5rem 1.5rem;
    font-size: 12px;
    color: #DAE7E4;
    border-radius: 6px;
    text-decoration: none;
    background-color: $pineGreen;
    border: solid 1px $pineGreen;
}

.chooseButtonInactive {
    margin: 1rem;
    padding: 0.5rem 1.5rem;
    font-size: 12px;
    color: #DAE7E4;
    border-radius: 6px;
    text-decoration: none;
    background-color: transparent;
    border: solid 1px $pineGreen;
}
