@import "../.././styles/Dashboards.scss";
@import "../.././styles/colors.scss";
@import "../.././styles/typography.scss";
@import "../.././styles/AllEntities.scss";

.selectInput {
  width: 50%;
  padding: 0.5rem;
  border-radius: 10px;
  margin: 1.5rem 0;
  background-color: transparent;
  border: solid 1px $pineGreen;
  color: $khaki;
  font-size: 13px;
}

.options {
  color: $label;
  background-color: $darktablebg;
}

.resetButton {
  border-radius: 10px;
  border-style: none;
  background-color: $pineGreen;
  text-decoration: none;
  color: $sand;
  padding: 0.5rem 2rem;
  margin: 1.5rem 0;
  box-shadow: 0px 0px 15px $pineGreen;
  font-size: 12px;
}

.resetButton:hover {
  font-weight: 900;
}
