@import "../../../styles/Forms.scss";

@mixin modal-card($backgroundColor, $fontsColor) {
  background: $backgroundColor;
  color: $fontsColor;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  border-radius: 16px;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
  padding: 5rem 7rem;
  justify-content: center;
}

.modal {
  @include modal-card(white, black);
}

.modalDark {
  @include modal-card(#404040, white);
}

.darkBG {
  background-color: rgb(51, 51, 51, 0.6);
  width: 100vw;
  height: 250vh;
  z-index: 10;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
}

.formInputs {
  border-style: solid;
  padding: 1.5rem;
  margin: 1rem 1rem;
}

.backLink {
  font-size: 20px;
  text-decoration: none;
  color: #6d9e93;
  font-weight: 900;
  cursor: pointer;
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}

.backLink:hover {
  text-decoration: underline;
}

.submitButton {
  background-color: #6d9e93;
  color: white;
  margin: 2rem auto;
  width: 50%;
  display: block;
}

.mainTitle {
  color: #6d9e93;
  margin: 0.5rem 0 2rem 0;
  font-weight: bold;
}

.inputTitle {
  color: #6c757d;
  margin-top: 1rem;
  padding-bottom: 0;
}

.inputTitleDark {
  color: white;
  margin-top: 1rem;
  padding-bottom: 0;
}

/* Style for the checkbox container */
.custom-checkbox {
  display: inline-block;
  position: relative;
  height: 16px;
  border: 1px solid #ccc; /* Border color */
  border-radius: 3px; /* Rounded edges */
  background-color: white; /* Background color */
  cursor: pointer;
  vertical-align: middle;
}

.mediumFont {
  font-size: 12pt;
}

.smallFont {
  font-size: 13px;
}