.emptyMessage {
  text-align: center;
  font-weight: 300;
  font-size: 25px;
  color: #5c8f84;
  margin: 2rem 0;
}

.emptyIconDark {
  font-size: 80px;
  color: #6d9e93;
  margin: 4rem 0 1rem 0;
}
