// Checkbox

.checkbox {
  display: inline-flex;
  cursor: pointer;
  position: relative;
  animation-name: createButtonAnimation;
  animation-duration: 1.5s;
  animation-timing-function: ease-in;
}

.checkbox > span {
  color: #768594;
  padding: 0.5rem 1rem;
}

.checkbox > input {
  height: 20px;
  width: 20px;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  border: 1px solid #34495e;
  border-radius: 4px;
  outline: none;
  transition-duration: 0.7s;
  background-color: white;
  cursor: pointer;
  margin-top: 0.5rem;
}

.checkbox > input:checked {
  border: 2px solid #cdf3eb;
  background-color: #6d9e93;
}
